export default {
  about: 'Về',
  add: 'Thêm vào',
  addLink: 'Thêm liên kết',
  addNewNft: 'Thêm NFT mới',
  addRule: 'Thêm quy tắc',
  addWallet: 'Thêm ví',
  active: 'Tích cực',
  all: 'Tất cả',
  allPeople: 'Tất cả mọi người',
  allPortals: 'Tất cả các cổng',
  allVolume: 'Tất cả vol',
  allowFrames: 'Cho phép khung',
  alsoCommented: 'cũng nhận xét',
  andOthers: 'và những người khác',
  and: 'và',
  access: 'Truy cập',
  accessDescription:
    'Ví được ghép nối sẽ hiển thị ở đây. Chiến lợi phẩm Solana từ lootbox sẽ được gửi đến ví chính của bạn.',
  appsAndSocial: 'Ứng dụng & Xã hội',
  arcade: {
    creatorSpotlight: 'Tiêu điểm Người sáng tạo',
    discoverGamingPortals: 'Khám phá cổng game',
    enjoyGames:
      'Thưởng thức các trò chơi miễn phí hoặc chơi để giành chiến thắng',
    moreGamesComing: 'Nhiều trò chơi và bảng xếp hạng cộng đồng sắp ra mắt.',
    newToArcade: 'Mới đến arcade',
    owner: 'Người sở hữu',
    slideTitle: 'Bạn muốn gửi trò chơi của mình?',
    slidePrimaryButtonText: 'Ứng tuyển tại đây',
    slideSecondaryButtonText: 'Tìm hiểu thêm',
    welcome: 'Chào mừng đến với DSCVR ARCADE',
  },
  auth: {
    subtitle: 'Vượt qua ranh giới của phương tiện truyền thông xã hội Web3',
    detectionForm: {
      accountFound: 'Tìm thấy nó!',
      yourAccount: 'Tài khoản của bạn',
      wasMadeWith: 'đã được thực hiện với',
      useNonWalletBrowser:
        'Vui lòng sử dụng trình duyệt không phải ví trên thiết bị của bạn như Chrome, Firefox hoặc Safari để đăng nhập.',
      clickOneOfTheButtons: 'Bấm vào một trong các nút',
      clickTheButton: 'Nhấn vào nút',
      belowToContinue: 'bên dưới để tiếp tục.',
      signInWith: 'Đăng nhập với',
      username: 'tên tài khoản',
    },
    accountNotFound: {
      notFoundWithNFID:
        'Tài khoản không được tìm thấy.Vui lòng đảm bảo rằng bạn đang sử dụng tài khoản NFID chính xác',
      notFoundWithII:
        'Tài khoản không được tìm thấy.Vui lòng đảm bảo rằng bạn đang sử dụng mỏ neo danh tính internet chính xác',
      signInWithUsername: 'Đăng nhập bằng tên người dùng',
    },
    mobileWalletApp: {
      title: 'Sử dụng ứng dụng ví di động',
      description:
        'Vui lòng đăng nhập vào DSCVR trong ứng dụng ví di động của bạn.',
    },
    loginForm: {
      byContinuing: 'Bằng cách tiếp tục, bạn đồng ý với chúng tôi',
      termOfService: 'Điều khoản dịch vụ',
      andOur: 'và của chúng tôi',
      privacyPolicy: 'Chính sách bảo mật.',
    },
    registrationForm: {
      createAccount: 'Tạo tài khoản',
    },
  },
  back: 'Mặt sau',
  backToProfile: 'Quay lại hồ sơ',
  backToFeeds: 'Quay lại thức ăn',
  backToPost: 'Quay lại bài viết',
  balance: 'Sự cân bằng',
  ban: 'Lệnh cấm',
  banMember: 'Cấm thành viên',
  block: 'Khối',
  blocked: 'Bị chặn',
  bookmarks: 'Dấu trang',
  browseGames: 'Duyệt trò chơi',
  canisterPrincipalId: 'Canister principal id',
  canisterIdErrorRequired: 'ID hộp được yêu cầu',
  canisterIdErrorInvalid: 'ID hiệu trưởng không hợp lệ',
  cancel: 'hủy bỏ',
  canvas: {
    errorLoading: 'Lỗi tải Canvas',
    emptyPostContentError: 'Nội dung bài đăng có thể trống',
    inEditorError: 'Ứng dụng Canvas chưa được đăng',
    unknownMessageError: 'Canvas: Thông điệp không xác định',
  },
  chain: 'Xích',
  chainErrorRequired: 'Chuỗi là bắt buộc',
  chooseYourPicture: 'Chọn hình ảnh của bạn',
  claimed: 'Đã yêu cầu',
  claim: 'Khẳng định',
  claiming: 'Yêu cầu',
  claimedDescription: 'Bạn đã nhận được chiến lợi phẩm của mình',
  claimFive: 'Yêu cầu 5 điểm',
  claimHundred: 'Yêu cầu 100 điểm',
  claimLootbox: 'Yêu cầu lootbox',
  collab: 'Hợp tác',
  collaborateOnAtlas3: 'Hợp tác trên Atlas3',
  collectionAddress: 'Thu tập địa chỉ',
  collectionAddressErrorRequired: 'Địa chỉ bộ sưu tập là bắt buộc',
  collectionAddressErrorInvalid: 'Địa chỉ bộ sưu tập không hợp lệ',
  collectionAddressLegend:
    'Vui lòng sử dụng địa chỉ "On-chain Collection" có sẵn trên Magic Eden cho bất kỳ NFT nào từ bộ sưu tập',
  collectionName: 'Tên bộ sưu tập',
  collectionNameErrorRequired: 'Tên thu thập được yêu cầu',
  collectionErrorRequired: 'Bộ sưu tập là bắt buộc',
  collections: 'Bộ sưu tập',
  collectAirdrops: 'Thu thập airdrops miễn phí',
  comeBackIn: 'Hãy quay lại',
  comments: 'Bình luận',
  connectWallet: 'Kết nối ví',
  continue: 'Tiếp tục',
  copied: 'Đã sao chép!',
  copiedToClipboard: 'Sao chép vào clipboard',
  copyPrincipal: 'Sao chép ID chính',
  create: 'Tạo nên',
  createAirdrop: 'Tạo airdrop',
  createdBy: 'Được tạo bởi',
  createNewRole: 'Tạo vai trò mới',
  createNftCollectionError: 'Có một vấn đề tạo ra nft gating',
  createNftCollectionSuccess: 'NFT Gating bật',
  createPortal: 'Tạo cổng thông tin',
  createPost: 'Tạo bài viết',
  createRoleError: 'Có một vấn đề tạo ra vai trò',
  createRoleSuccess: 'Vai trò được tạo ra thành công',
  day: 'Ngày',
  days: 'ngày',
  delete: 'xóa',
  deleteConfirmationMessage: 'Bạn có chắc bạn muốn xóa cái này không?',
  deleteNftCollectionError: 'Có một vấn đề khi xóa NFT Gating',
  deleteNftCollectionSuccess: 'NFT Gating đã xóa thành công',
  deleteNftGate: 'Xóa cổng NFT',
  description: 'Sự miêu tả',
  disableComments: 'Tắt nhận xét',
  dragImage: 'Kéo và thả hình ảnh vào đây để tải lên',
  dragMedia: 'Nhấp hoặc kéo ở đây để tải lên hình ảnh hoặc video của bạn.',
  dropFilesHere: 'Thả tập tin của bạn ở đây',
  dscvrdPoints: 'Có vẻ như bạn đã DSCVRed một số điểm.',
  duration: 'Khoảng thời gian',
  earned: 'kiếm được',
  edit: 'Biên tập',
  editProfile: 'Chỉnh sửa hồ sơ',
  editProfilePhoto: 'Chỉnh sửa ảnh hồ sơ',
  editRule: 'Chỉnh sửa quy tắc',
  embedImage: 'Hình ảnh nhúng',
  unpairedWalletNotification: 'Bạn chưa thêm ví',
  error: 'lầm lỡ',
  errorLoadingImage: 'Lỗi Đang tải...',
  explore: 'Khám phá',
  exploreAndDiscover: 'Khám phá và khám phá',
  exploreMore: 'Tìm hiểu thêm',
  exploreSearchPlaceholderLarge:
    'Tham gia với mọi người và khám phá cổng thông tin ...',
  exploreSearchPlaceholderSmall: 'Tìm cộng đồng của bạn ...',
  favorites: 'Yêu thích',
  favoritePortals: {
    addToFavorites: 'Thêm vào mục yêu thích',
    applyChanges: 'Áp dụng các thay đổi',
    favorite: 'Yêu thích',
    favoritesEmptyLegend:
      'Lưu các cổng yêu thích của bạn để truy cập nhanh tại đây. Mở rộng vũ trụ của bạn bằng cách thêm một vài bây giờ.',
    favoritesLegend: 'Lưu các cổng yêu thích của bạn để truy cập nhanh tại đây',
    noResults: 'Ko có kết quả',
    otherFavorites: 'Yêu thích khác',
    owned: 'Sở hữu',
    pleaseJoinToFavorite: 'Hãy tham gia để yêu thích',
    removeFromFavorites: 'Loại bỏ khỏi mục ưa thích',
    reorder: 'Sắp xếp lại',
    topFavorited: 'Hàng đầu được yêu thích',
  },
  favoritesEmptyLegend:
    'Lưu cổng thông tin yêu thích của bạn để truy cập nhanh tại đây.Mở rộng vũ trụ của bạn bằng cách thêm một vài bây giờ.',
  favoritesLegend:
    'Lưu cổng thông tin yêu thích của bạn để truy cập nhanh tại đây',
  featured: 'Đặc sắc',
  featuredPortals: 'Cổng thông tin nổi bật',
  floorPrice: 'Giá sàn',
  follower: 'Người theo dõi',
  followers: 'Người theo dõi',
  following: 'Tiếp theo',
  frame: {
    address: 'Địa chỉ',
    buttonIndex: 'Chỉ mục nút',
    commentsMaxReached: 'Nhận xét phải nhỏ hơn 1000 ký tự',
    debug: 'Gỡ lỗi',
    errorPost: 'Lỗi thực hiện khung bài',
    flagFrame: 'Khung cờ',
    frameAction: 'Hành động khung',
    frameIntroduction: 'Giới thiệu khung',
    frameLink: 'Liên kết khung',
    frameNotFoundError: 'Khung không tìm thấy',
    framesValidator: 'Công cụ xác nhận khung',
    frameUrl: 'Khung URL',
    frameUrlErrorRequired: 'Url khung là bắt buộc',
    inputText: 'Nhập ký tự',
    maxResponseTimeLegend:
      'Yêu cầu mất hơn 5s.Điều này có thể là bình thường: Yêu cầu đầu tiên sẽ mất nhiều thời gian phát triển hơn, nhưng trong sản xuất, khách hàng sẽ hết thời gian chờ sau 5s',
    openFramesSpec: 'Mở khung thông số kỹ thuật',
    people: 'mọi người',
    postId: 'Đăng ID',
    postIdErrorInvalid: 'ID bài viết không hợp lệ',
    request: 'Lời yêu cầu',
    response: 'Phản ứng',
    specs: 'Thông số kỹ thuật',
    state: 'Tình trạng',
    times: 'thời gian',
    timestamp: 'Dấu thời gian',
    transactionId: 'ID giao dịch',
    trustedMessage: 'Tin nhắn đáng tin cậy',
  },
  gallery: 'Phòng trưng bày',
  getNFT: 'Nhận NFT',
  gift: 'quà tặng',
  gotToSettings: 'Chuyển đến Cài đặt',
  guest: 'Khách mời',
  guests: 'Khách',
  hide: 'Trốn',
  home: 'Trang Chủ',
  hotSpots: 'Điểm nóng',
  howToSetupPhantom: 'Cách thiết lập ví ma',
  installMetamaskTitle: 'Không phát hiện ra metamask',
  installMetamaskLegend: 'Vui lòng mở khóa hoặc cài đặt Metamask',
  invalidImageUrlError: 'Vui lòng nhập URL hình ảnh hợp lệ',
  invalidUrlError: 'Đây có vẻ không phải là một url hợp lệ',
  invite: 'Mời',
  inviteToPortal: 'Mời đến Cổng thông tin',
  itemId: 'ID mục',
  join: 'Tham gia',
  joined: 'Tham gia',
  key: 'Chìa khóa',
  keyboardC: 'Tab bình luận',
  keyboardDown: 'Cuộn xuống',
  keyboardG: 'Tab thư viện',
  keyboardL: 'Giống như một bài viết',
  keyboardLeft: 'Chuyển đến bài viết trước',
  keyboardR: 'Phản ứng mở',
  keyboardRight: 'Chuyển đến bài tiếp theo',
  keyboardS: 'Chuyển đổi menu phím tắt',
  keyboardShortcuts: 'Các phím tắt bàn phím',
  keyboardUp: 'Cuộn lên',
  kick: 'đá',
  kickMember: 'Đá thành viên',
  lastUsed: 'Lần sử dụng cuối cùng',
  learnMore: 'Tìm hiểu thêm',
  leave: 'Rời khỏi',
  leaveConfirm: 'Bạn có chắc chắn muốn rời đi?',
  leavePortal: 'Rời khỏi cổng thông tin',
  leavePortalLegend: 'Bạn có chắc là bạn muốn rời khỏi cổng thông tin này?',
  linkWarning: 'Vui lòng chỉ sử dụng liên kết "https://"',
  listedSupply: 'Liệt kê / cung cấp',
  listeningNow: 'Bây giờ hãy lắng nghe',
  login: 'Đăng nhập',
  lootbox: {
    creatorLootboxFound: 'Đã tìm thấy lootbox của người sáng tạo',
    criteria: 'Tiêu chuẩn',
    claimSubtitle: {
      sm: 'Kho báu của bạn đang chờ đợi',
      md: 'Kho báu của bạn đang chờ đợi. Yêu cầu các chiến lợi phẩm còn lại từ hộp mở của bạn.',
    },
    claimTitle: {
      sm: 'Loot không có người nhận!',
      md: 'Bạn đã có những chiến lợi phẩm không có người nhận!',
    },
    claimYourLoot: {
      sm: 'Khẳng định',
      md: 'Yêu cầu loot của bạn',
    },
    luckyYouCreator: 'May mắn! Bạn đã tìm thấy lootbox của người sáng tạo',
    loot: 'Cướp bóc',
    lootbox: 'Hộp lootbox',
    lootEarned: 'Loot kiếm được',
    mustBeMemberOf: 'Phải là thành viên của',
    mustFollow: 'Phải theo',
    pairError: 'Bạn phải có một ví Solana được ghép nối để mở hộp lootbox này.',
  },
  luckyDay: 'Chào mừng bạn đến với ngày may mắn của bạn!',
  makeCoHost: 'Làm đồng chủ nhà',
  managed: 'Quản lý',
  manageWallets: 'Quản lý ví',
  markAsNSFW: 'Đánh dấu là NSFW',
  managePortals: 'Quản lý Cổng',
  marketplace: 'Thương trường',
  marketplaceCollectionsLegend:
    'Lặn vào một kho báu của nfts để lấy.Một số bộ sưu tập đóng vai trò là chìa khóa vàng của bạn để mở khóa cổng thông tin tuyệt vời này. 🔑✨',
  marketplaceCollectionItemLegend: 'Mở khóa cổng này. 🔑✨',
  matureContent: 'Nội dung người lớn (NSFW)',
  member: 'Thành viên',
  members: 'Các thành viên',
  memberSince: 'Thành viên kể từ',
  more: 'hơn',
  mustJoinPortalMessage: 'Bạn phải tham gia cổng thông tin để xem nội dung này',
  myProfile: 'Thông tin của tôi',
  newRoleName: 'Tên vai trò mới',
  network: 'Mạng',
  nextPage: 'Trang tiếp theo',
  nftAirdrop: {
    setAsPFP: 'Đặt dưới dạng PFP',
    viewOnMarketplace: 'Xem trên Entrepot',
  },
  nftCollection: 'Bộ sưu tập NFT',
  nftGate: 'Cổng NFT',
  nftGates: 'Cổng NFT',
  nftGatesLegend:
    'NFT Gate Cổng thông tin của bạn, cung cấp cho các thành viên của bạn vai trò dựa trên quyền sở hữu NFT.',
  nftHolderRole: 'Vai trò của NFT',
  nftTransfer: 'Chuyển giao NFT',
  nftType: 'Loại NFT',
  nftTypeErrorRequired: 'Loại NFT là bắt buộc',
  noCommentsYet: 'Chưa có nhận xét nào',
  noGalleryItems: 'Chưa có vật phẩm nào',
  noLanguage: 'Bạn chưa thiết lập ngôn ngữ dịch!',
  noRecentPortal: 'Không có cổng nào gần đây',
  notifications: {
    title: 'Thông báo',
    portalAdmin: 'Quản trị viên cổng thông tin',
    empty: {
      title: 'Ở lại trong vòng lặp',
      excerpt:
        'Không bao giờ lỡ nhịp khi ai đó thích, bình luận hoặc chia sẻ bài viết của bạn.',
    },
    options: {
      title: 'Tùy chọn',
      grouping: {
        title: 'Thông báo nhóm',
        subtitle:
          'Hợp nhất các thông báo tần suất cao thành các tin nhắn được nhóm.',
      },
    },
    preferences: {
      title: 'Sở thích',
      commentsAndReplies: 'Nhận xét và trả lời',
      newFollowers: 'Người theo dõi mới',
      likesAndReactions: 'Lượt thích và phản ứng',
      mentions: 'Đề cập',
      streakReminders: 'Liên tục nhắc nhở qua email',
    },
    content: {
      postMention: 'đã đề cập đến bạn trong một bài viết',
      commentMention: 'đã đề cập đến bạn trong một bình luận',
      postReply: 'đã trả lời bài viết của bạn',
      postMediaReply:
        'đã trả lời bài đăng trên phương tiện truyền thông của bạn',
      commentReply: 'đã trả lời bình luận của bạn',
      postReaction: 'đã phản hồi bài đăng của bạn',
      postMediaReaction:
        'đã phản ứng với bài đăng trên phương tiện truyền thông của bạn',
      commentReaction: 'đã phản hồi bình luận của bạn',
    },
    inAppTitle: 'Tôi muốn nhận',
    markAllAsRead: 'đánh dấu tất cả như đã đọc',
    settings: {
      streakRemindersErrorTitle: 'Vui lòng xác minh email của bạn.',
    },
    viewAllNotifications: 'Xem tất cả thông báo',
  },
  nsfwTooltipContent:
    'Chỉ dành cho khán giả trưởng thành và có khả năng là NSFW',
  onboarding: {
    introductory: {
      welcome: 'Chào mừng',
      title: 'Hoàn tất quá trình giới thiệu để nhận phần thưởng',
      description:
        'Chúng tôi biết việc tham gia có thể rất tẻ nhạt. Để bù đắp cho điều đó, chúng tôi muốn tặng bạn những món quà sau nếu bạn hoàn thành tất cả các bước.',
      rewards: {
        title: 'Kiếm phần thưởng',
        description:
          'Hoàn tất quá trình tham gia để kiếm Điểm DSCVR đầu tiên của bạn, loại tiền tệ xã hội của DSCVR có thể được sử dụng để tạo cộng đồng đầu tiên của bạn.',
      },
      lootbox: {
        title: 'Nhận một lootbox miễn phí',
        description:
          'Lootbox chứa NFT, mã thông báo miễn phí và các tài sản kỹ thuật số khác từ các cộng đồng và dự án có nguồn gốc tiền điện tử nổi bật nhất.',
      },
    },
    interest: {
      title: 'Bạn quan tâm đến điều gì?',
      description:
        'Chọn 3 chủ đề trở lên để giúp chúng tôi quản lý nguồn cấp dữ liệu của bạn',
      noneOfThese: 'Không ai trong số này',
    },
    discoverUsers: {
      title: 'Khám phá những người dùng hàng đầu',
      description:
        'Theo dõi những người dùng phổ biến nhất của chúng tôi để luôn cập nhật và tham gia cuộc trò chuyện.',
      follow: 'Theo',
    },
    joinPortals: {
      title: 'Tham gia cổng thông tin',
      description:
        'Chúng tôi đã tìm thấy một số cổng phù hợp với sở thích của bạn.',
      descriptionNoInterests:
        'Hãy xem các cổng thông tin tích cực và hấp dẫn nhất của chúng tôi.',
      join: 'Tham gia',
    },
    missingSteps: {
      title: 'Hoàn tất quá trình giới thiệu để yêu cầu',
      user: {
        title: 'Theo dõi ít ​​nhất ba người',
        description:
          'Kết nối với những người dùng khác là một phần cốt lõi của DSCVR.',
      },
      portal: {
        title: 'Tham gia ít nhất ba cổng',
        description:
          'Tìm kiếm cộng đồng là một phần khiến DSCVR trở nên thú vị và giải trí.',
      },
      completeOnboarding: 'Hoàn tất quá trình giới thiệu',
    },
    rewards: {
      title: 'Bạn đã kiếm được 50 điểm DSCVR chỉ khi đăng ký!',
      description:
        'Tích lũy điểm DSCVR bằng cách giành chuỗi điểm hàng ngày của bạn, đăng nội dung được bình chọn cao và giới thiệu bạn bè. Điểm DSCVR có thể giúp bạn đủ điều kiện nhận lootbox và truy cập vào các cổng đặc biệt.',
      claimNow: 'Yêu cầu ngay bây giờ',
      claimed: 'kêu nài',
    },
    nft: {
      title: 'Bạn đã kiếm được NFT độc quyền!',
      subtitle: 'Đặt làm hình ảnh hồ sơ của bạn và kiếm được 50 điểm.',
      description:
        'NFT của bạn đang trên đường tới ví của bạn. Việc này có thể mất tới một giờ nếu điều kiện mạng bận. Nếu bạn đặt NFT làm PFP của mình, bạn sẽ được thưởng 50 điểm DSCVR.',
      viewMyWallet: 'Xem ví của tôi',
    },
    connectYourWallet: {
      title: 'Kết nối ví của bạn',
      description: 'Để yêu cầu NFT độc đáo này, bạn phải kết nối ví.',
      connectWallet: 'Kết nối ví',
      error: 'Đã xảy ra lỗi khi kết nối ví của bạn',
    },
    whatIsInTheBox: 'Cái gì trong chiếc hộp này vậy?!',
    unlock: 'Mở khóa',
    unlockLootbox: 'Mở khóa lootbox',
    claim: {
      tooltipContent:
        'Yêu cầu chuỗi đầu tiên của bạn để kiếm được nhiều điểm hơn!',
    },
    newUser: 'Người dùng mới nhận được chiến lợi phẩm độc quyền!',
    chooseAtLeast3: 'Chọn ít nhất 3',
    skip: 'Skip',
  },
  open: 'Mở',
  opened: 'Đã mở',
  opening: 'Khai mạc',
  pair: 'Đôi',
  paired: 'Ghép đôi',
  people: 'Mọi người',
  pickPortal: 'Chọn cổng thông tin',
  play: 'Chơi',
  points: 'Điểm',
  pointsAdded: 'Chúng tôi đã thêm thêm 5 điểm vào tài khoản của bạn.',
  pointsReceived: 'Điểm nhận được',
  pollEnptyQuestionError: 'Không được để trống câu hỏi',
  pollMinQuestionsError: 'Bạn phải có ít nhất 2 câu hỏi thăm dò ý kiến',
  pollQuestionMaxCharsError: 'Câu hỏi không được dài hơn 120 ký tự',
  pollRequiredVotingPeriodError: 'Bạn phải đặt khoảng thời gian biểu quyết.',
  popularPortals: 'Cổng phổ biến',
  portal: 'Cổng thông tin',
  portalAdmin: 'Quản trị cổng thông tin',
  portalDoNotExist: 'Cổng thông tin này không tồn tại',
  portalError: 'Chọn một cổng trước khi đăng',
  portalInvite: {
    buildCommunity:
      'Xây dựng cộng đồng của bạn. Sao chép liên kết giới thiệu và tải hình ảnh để mời bạn bè đến cổng thông tin của bạn.',
    buildProfile:
      'Xây dựng cộng đồng của bạn. Sao chép liên kết giới thiệu và tải hình ảnh để mời bạn bè theo dõi hồ sơ của bạn.',
    inviteFriends: 'Mời bạn bè của bạn',
    joinPortal: 'Tham gia cổng thông tin này',
    followUser: 'Tham gia cộng đồng',
    invited: 'Bạn đã được mời!',
    invitedToJoin: 'Bạn đã được mời tham gia',
    invalidReferralCode: 'Mã giới thiệu không hợp lệ',
    embraceFuture:
      'Hãy đón nhận tương lai của Mạng xã hội! Tham gia vào một cộng đồng phi tập trung được xây dựng dành cho bạn!',
  },
  portalPermissionError: 'Bạn không có quyền đăng lên cổng thông tin này.',
  portalRestrictedSubtitle:
    'Bạn không có quyền truy cập vào cổng thông tin này.',
  portalRestrictedNote:
    'Nếu bạn vừa ghép nối hoặc nhận NFT, có thể mất vài phút để Cổng này mở khóa.',
  portalRestrictedTitle: 'Có được một NFT để tham gia',
  portalNSFWRestrictedError:
    'Bật NSFW trong cài đặt của bạn để xem nội dung này',
  post: 'đăng',
  postButtonLabelGift: 'Quà',
  postButtonLabelReply: 'Hồi đáp',
  postButtonLabelRepost: 'Đăng lại',
  postButtonLabelShare: 'Chia sẻ',
  postButtonLabelToken: 'Lời khuyên',
  postCreated: 'Bài viết của bạn đã được tạo thành công',
  postDeletedConfirm: 'Bài đăng chưa được lưu của bạn sẽ bị xóa.',
  postError: 'Điền vào nội dung bài đăng trước khi đăng',
  postFeature: {
    commented: 'Nhận xét',
    errorDelete: 'Đã xảy ra lỗi khi xóa bài đăng này, vui lòng thử lại',
    errorRemove: 'Đã xảy ra lỗi khi xóa bài đăng này, vui lòng thử lại',
    interacted: 'Tương tác',
    originalPostDoNotExist:
      'Bài đăng gốc không còn tồn tại nhưng bạn có thể xem các bài đăng phổ biến khác.',
    postDoNotExist: 'Bài đăng này không tồn tại',
    postRestricted: 'Bạn không có quyền xem bài đăng này.',
    replied: 'đã trả lời',
  },
  postUpdated: 'Bài đăng của bạn đã được cập nhật thành công',
  posts: 'Bài viết',
  postTo: 'Đăng lên',
  prevPage: 'Trang trước',
  primaryChain: 'Chuỗi chính',
  primaryWallet: 'Sơ đẳng',
  principalId: 'ID chính',
  purchased: 'Đã mua',
  raisedHands: 'giơ tay',
  reason: 'Lý do',
  readMore: 'Đọc thêm',
  recent: 'Gần đây',
  recommended: 'Khuyến khích',
  referral: 'Giới thiệu',
  referrals: 'Giới thiệu',
  referralFeature: {
    community: {
      referrals: {
        capacitor: 'người có tài',
        copyReferralLink: 'Sao chép liên kết giới thiệu',
        description:
          'Muốn có điểm thụ động? Giới thiệu người dùng và nhận 1 điểm thưởng cho mỗi 10 điểm họ kiếm được. Ngoài ra, hãy sạc tụ điện của bạn bằng cách kiếm 100 điểm thưởng để nhận Hệ số Airdrop!',
        empty: 'Trống',
        title: 'Tụ điện của bạn đang trống!',
      },
    },
    getRewards: 'Được nhận thưởng!',
    inviteYourFriends: 'Mời bạn bè của bạn',
    multipliers: 'Số nhân',
    multiplier: 'Số nhân',
    onboarding: {
      title: 'Kết nối bạn bè của bạn, nhận phần thưởng',
      bonus: {
        title: 'Nhận điểm thưởng một cách thụ động',
        description:
          'Nhận 1 điểm thưởng cho mỗi 10 điểm mà người giới thiệu của bạn kiếm được.',
      },
      airdrop: {
        title: 'Tối đa hóa hệ số nhân Airdrop của bạn',
        description:
          'Với mỗi 100 điểm thưởng bạn kiếm được thông qua giới thiệu, bạn sẽ nhận được Hệ số Airdrop.',
      },
    },
    tooltipContent: 'Gửi cho bạn bè của bạn một liên kết mời ở đây',
    copyLink: 'Liên kết được sao chép vào khay nhớ tạm',
  },
  remove: 'Di dời',
  removeConfirmationMessage: 'Bạn có chắc là bạn muốn loại bỏ cái này?',
  removeLink: 'Xóa liên kết',
  removeLinkLegend: 'Bạn có chắc là bạn muốn xóa liên kết này?',
  removeRule: 'Loại bỏ quy tắc',
  removeRuleLegend: 'Bạn có chắc là bạn muốn xóa quy tắc này?',
  repliedTo: 'trả lời cho',
  reply: 'trả lời',
  report: 'Báo cáo',
  requiredUrlError: 'bạn cần một url cho việc này',
  requiresFifty: 'Yêu cầu: 50',
  roleErrorRequired: 'Vai trò là bắt buộc',
  roleNameErrorDuplicated: 'Tên vai đã tồn tại',
  roleNameErrorLength: 'Tên vai phải nằm trong khoảng từ 1 đến 20 ký tự',
  roleNameErrorRequired: 'Tên vai trò là bắt buộc',
  rollTheDice: 'Tung súc sắc!',
  ruleTitle: 'Tiêu đề quy tắc',
  ruleDescription: 'Mô tả quy tắc',
  runtime: 'thời gian chạy',
  scrollAndDiscover: 'Cuộn và khám phá',
  search: 'Tìm kiếm',
  searchNftCollection: 'Tìm kiếm bộ sưu tập NFT',
  save: 'lưu',
  searchPortals: 'Cổng tìm kiếm',
  selectLanguage: 'Chọn ngôn ngữ dịch',
  selectPrimaryChain: 'Chọn chuỗi chính',
  selectSiteLanguage: 'Ngôn ngữ',
  selectSitePortalSorting: 'Sắp xếp cổng thông tin',
  selectSitePrimaryLanguage: 'Chọn ngôn ngữ chính',
  selectSiteTranslatedLanguage: 'Dịch',
  selectSiteTranslatedPrimaryLanguage: 'Chọn bản dịch',
  send: 'Gửi',
  sendGift: 'Tặng quà?',
  sendGiftLegend:
    'Bạn có chắc chắn bạn muốn tặng NFT này không?Hành động này không thể được hoàn tác.',
  setLanguage: 'Đặt ngôn ngữ dịch',
  userSettings: {
    address: 'Địa chỉ',
    avatarRequirements: 'ít nhất 280 x 280 px',
    coverPhotoRequirements: 'Ít nhất 1088x300 px',
    errorUploadingFile: 'Có một lỗi khi tải lên tệp',
    invalidIcpNftAddressMessage: 'Chỉ có thể sử dụng NFT IC hợp lệ',
    invalidImageFileMessage: 'Please upload an image file',
    updateProfileError: 'Có một lỗi khi cập nhật hồ sơ',
    username: 'tên tài khoản',
    userProfile: 'Thông tin người dùng',
  },
  setupNftWatcher: 'Bật Gating NFT',
  setupNftWatcherSuccess: 'NFT Gating được bật',
  share: 'chia sẻ',
  sharePortal: 'Cổng chia sẻ',
  shareProfile: 'Chia sẻ hồ sơ',
  show: 'Trình diễn',
  showcaseNFTs: 'Hiển thị Phòng trưng bày Solana NFT của bạn',
  showContent: 'Hiển thị nội dung',
  showInMarketplace: 'Hiển thị trên thị trường',
  signup: 'Đăng ký',
  signUp: {
    thrilledRegistration:
      'Chúng tôi rất phấn khích khi bạn đã chọn tham gia cộng đồng của chúng tôi, nơi có những người sáng tạo và tư duy độc đáo! Chỉ còn một bước cuối cùng thôi.',
    alreadyHaveAccount: 'Đã có tài khoản?',
    dontHaveAccount: 'Vẫn chưa có tài khoản?',
  },
  sort: 'Loại',
  speaker: 'Loa',
  speakers: 'Diễn giả',
  spotlight: 'Điểm sáng',
  status: 'Trạng thái',
  streak: {
    airdropMultiplier: 'Hệ số nhân airdrop',
    earned: 'Kiếm được',
    highest: 'Cao nhất',
    highestStreak: 'Chuỗi cao nhất',
    streak: 'Vệt',
    totalEarned: 'Tổng số tiền kiếm được',
    informationDialog: {
      title: 'Nhân cơ hội của bạn lên một Airdrop độc quyền!',
      lightning: {
        title: 'Tăng cơ hội của bạn với các vệt',
        description:
          'Đăng nhập và nhấn nút xác nhận để tăng cơ hội nhận được airdrop độc quyền trong nguồn cấp dữ liệu của bạn.',
      },
      plus: {
        title: 'Kiếm điểm DSCVR mỗi ngày bạn đăng nhập',
        description:
          'Bắt đầu thu thập điểm của bạn. Càng đánh được nhiều vệt, bạn càng nhận được nhiều điểm.',
      },
    },
    shareDialog: {
      title: {
        global: 'Tát! Bạn đã tích lũy Hệ số nhân Airdrop! Bạn đã kiếm được',
      },
      lightning: {
        title:
          'Hãy tiếp tục và kiếm được số nhân tiếp theo của bạn (chúng xếp chồng lên nhau)',
        description:
          'Đăng nhập và nhấn nút xác nhận để tăng cơ hội nhận được airdrop độc quyền trong nguồn cấp dữ liệu của bạn.',
      },
      plus: {
        title: 'Kiếm điểm DSCVR mỗi ngày bạn đăng nhập',
        description:
          'Bắt đầu thu thập điểm của bạn. Càng đánh được nhiều vệt, bạn càng nhận được nhiều điểm.',
      },
      shareYourStreak: 'Chia sẻ thành tích của bạn',
      unlockedAn: 'đã mở khóa một',
    },
    buttonLabel: 'Yêu cầu điểm của bạn',
    duringAStreakOf: 'trong một chuỗi',
    activeButton: {
      tooltipContent: 'Chọn nút này để nhận điểm của bạn!',
    },
    activeDialog: {
      allPoweredUp: 'Tất cả đã được cấp nguồn!',
      longStreaksEarnMore: 'Những vệt dài kiếm được nhiều hơn',
      powerUp: 'Tăng sức mạnh cho máy đo airdrop của bạn!',
      referFriend: 'Tham khảo một người bạn',
      toClaimNextReward: 'để nhận phần thưởng tiếp theo của bạn',
      unbrokenStreak: 'Các vệt không bị gián đoạn kiếm được nhiều điểm hơn!',
      unlockedAn: 'đã mở khóa một',
      wantMorePoints: 'Bạn muốn kiếm được nhiều điểm hơn?',
      youAreOnAStreak: 'Bạn đang trên đà thành công!',
      getNotified: 'Nhận được thông báo',
      optInToReceive: 'Chọn tham gia để nhận lời nhắc qua email',
      optedIn: 'Chọn tham gia',
      toast: {
        title: 'Bạn sẽ được thông báo!',
        description:
          'Bạn đã chọn nhận lời nhắc liên tục qua email. Truy cập cài đặt để thay đổi quyền.',
        gotoSettings: 'Đi tới cài đặt',
      },
    },
  },
  stay: 'Ở lại',
  submit: 'Nộp',
  takeMeToTheUnknown: 'Đưa tôi đến những điều chưa biết',
  titleError: 'Điền vào tiêu đề trước khi đăng',
  tokens: 'Mã thông báo',
  tokenTransfer: {
    amountErrorMessage: 'Số tiền phải lớn hơn 0',
    balanceErrorMessage: 'Không đủ số dư.',
    invalidWalletErrorMessage: 'Hiệu trưởng ví IC không hợp lệ',
    invalidWalletExtErrorMessage: 'Địa chỉ ví IC không hợp lệ hoặc hiệu trưởng',
    reviewTransfer: 'Đánh giá chuyển nhượng',
    title: 'Chuyển giao NFT',
    transferNftFailedMessage: 'Giao dịch chuyển nhượng NFT không thành công',
    transferTokenFailedMessage:
      'Giao dịch chuyển mã thông báo không thành công',
    transferNftLegend: 'Vui lòng xác nhận rằng bạn sắp gửi NFT',
    transferTokenLegend: 'Vui lòng xác nhận rằng bạn sắp gửi',
    transferWarning:
      'Tất cả các giao dịch là không thể đảo ngược, vì vậy hãy đảm bảo các chi tiết trên là chính xác trước khi bạn tiếp tục.',
    transferNftSuccessMessage: 'Việc chuyển NFT đã thành công',
    transferTokenSuccessMessage: 'Việc chuyển mã thông báo đã thành công',
    validationNftLegend:
      'Nhập địa chỉ ví máy tính Internet bạn muốn chuyển NFT này sang.',
    validationTokenLegend:
      'Nhập địa chỉ ví máy tính Internet bạn muốn chuyển mã thông báo này quá.',
    walletPlaceholder: 'IC Wallet Hiệu trưởng',
    walletPlaceholderExt: 'IC Wallet Hiệu trưởng hoặc địa chỉ',
  },
  tip: {
    contentOwnerHasNoWalletTitle: 'Chủ nội dung không có ví',
    contentOwnerHasNoWalletDescription:
      'Chủ nội dung không có ví nào được kết nối để nhận tiền thưởng',
    connectWallet: 'Kết nối ví của bạn để gửi tiền thưởng',
    signatureFailed: 'Chữ ký không hợp lệ',
    preparingTipTitle: 'Đang chuẩn bị tiền thưởng của bạn',
    preparingTipDescription:
      'Vui lòng chờ trong khi chúng tôi chuẩn bị tiền thưởng của bạn',
    noWalletConnectedTitle: 'Không có ví nào được kết nối',
    noWalletConnectedDescription:
      'Để gửi tiền thưởng, bạn cần kết nối ví của mình',
    successTitle: 'Tiền thưởng của bạn đã được gửi',
    successDescription: 'Tiền thưởng của bạn đã được gửi thành công',
  },
  top: 'Đứng đầu',
  transfer: 'Chuyển khoản',
  translate: 'dịch',
  unblock: 'dọn cho trống',
  unpair: 'Không ghép đôi',
  unsavedChanges: 'Xóa các thay đổi chưa được lưu?',
  update: 'Cập nhật',
  updateNftCollectionError: 'Có một vấn đề khi cập nhật giao thông NFT',
  updateNftCollectionSuccess: 'NFT Gating cập nhật thành công',
  upload: 'Tải lên',
  uploadImage: 'Tải lên hình ảnh',
  uploadProfilePhoto: 'Tải lên ảnh hồ sơ',
  uploadCoverPhoto: 'Tải lên ảnh bìa',
  useNFT: 'Sử dụng NFT',
  user: 'Người dùng',
  userBlocked: 'bị chặn',
  userDoNotExist: 'Người dùng này không tòn tại',
  userNoLongerBlocked: 'không còn bị chặn',
  userNoLongerFollowsYou: 'Không còn theo dõi bạn',
  verifiedSolanaPFPs: 'Xác minh Solana PFP',
  verifyPhone: 'Xác minh điện thoại',
  verifyPhoneLegend:
    'Bạn phải có một điện thoại được xác minh để tham gia cổng thông tin này',
  verifyingAtDSCVR: 'Xác minh tại DSCVR',
  voteCommentPermissionError: 'Bạn không được phép bỏ phiếu bình luận này',
  voteCommentError: 'Có một lỗi bỏ phiếu nhận xét này',
  view: 'Xem',
  viewAll: 'Xem tất cả',
  viewOnMagicEden: 'Xem trên Magic Eden',
  waiting: 'Chờ',
  wallet: 'Cái ví',
  wallets: {
    connectWallet: {
      connectAWallet: 'Kết nối ví',
      connecting: 'Đang kết nối',
      errorConnectingWallet: 'Lỗi kết nối ví',
      installed: 'Cài đặt',
      invalidWalletConnection: 'Kết nối ví không hợp lệ',
      mobileConnectError: 'Ví không thể được kết nối trên thiết bị di động',
      detected: 'Phát hiện',
      notDetected: 'Not detected',
      popular: 'Phổ biến',
    },
    evm: {
      providersNotFound: 'Nhà cung cấp Ethers không tìm thấy',
    },
    sendingToWallet: {
      title: 'Chúng tôi đang gửi bạn tới ví của bạn',
      description:
        'Hãy quay lại DSCVR khi bạn hoàn tất. Nếu bạn không nhận được tin nhắn xác nhận, hãy kết nối lại ví của bạn.',
      takeMeToMyWallet: 'Đưa tôi đến ví của tôi',
    },
    successfullyPaired: {
      successfullyConnected: 'Kết nối thành công!',
      takeMeToMyDscvrWallet: 'Đưa tôi đến ví DSCVR của tôi',
    },
    unsuccessfulPairing: {
      inApp: {
        pairingUnsuccessful: 'Ghép nối không thành công',
        pairThisAccount: 'Thay vào đó hãy ghép nối tài khoản này',
        useDifferentWallet: 'Sử dụng một ví khác',
        walletAlreadyPairedTo: 'Ví này được ghép nối với',
        pairingWillUnpairFrom: 'Ghép nối ví của bạn sẽ hủy ghép nối nó khỏi',
      },
      identity: {
        walletAlreadyPaired: 'Ví đã được ghép nối',
        walletAlreadyAssociated: 'Ví này được liên kết với ',
        signinWithThisAccount: 'Đăng nhập bằng tài khoản này',
      },
    },
    addAnotherWallet: 'Thêm ví khác',
    addressCopied: 'Địa chỉ được sao chép',
    allWallets: 'Tất cả ví',
    failedToLoad: 'Không tải được ...',
    mobileWalletPairing: 'Ghép cặp điện thoại di động',
    linkWalletFailed: 'Liên kết ví không thành công',
    linkWalletFailedDescription: 'Liên kết ví không thành công với địa chỉ: ',
    thanksForPairing:
      'Cảm ơn bạn đã ghép nối, bây giờ bạn có thể quay lại trình duyệt trên thiết bị di động của mình',
    address: 'Địa chỉ',
    sharingError: 'Không thể chia sẻ mã thông báo',
    refreshWallets: 'àm cho khỏe lại',
    verifyNftPfpError: 'Lỗi khi xác minh NFT',
    showAllAssets: 'Hiển thị tất cả các tài sản',
    matrica: {
      walletsRefreshedTitle: 'Ví Matrica của bạn đã được làm mới',
      walletsRefreshedDescription: 'Ví Matrica của bạn đã được làm mới',
      refreshWalletsErrorTitle: 'Làm mới ví Matrica thất bại',
      refreshWalletsErrorDescription:
        'Chúng tôi không thể làm mới và lấy ví Matrica của bạn.',
      refreshWalletsSuccessDescription:
        'Ví Matrica của bạn đã được làm mới thành công.',
      linkWalletLinkErrorTitle: 'Liên kết ví Matrica thất bại',
      linkWalletLinkErrorDescription:
        'Chúng tôi không thể liên kết ví Matrica của bạn.',
      linkWalletUnlinkErrorTitle: 'Ví Matrica không thể bỏ liên kết',
      linkWalletUnlinkErrorDescription:
        'Chúng tôi không thể bỏ liên kết ví Matrica của bạn.',
      redirectErrorMessage:
        'Chúng tôi không thể chuyển hướng bạn đến ví Matrica của bạn.',
    },
  },
  walletTransaction: {
    commentsErrorRequired: 'Bạn phải nhập nhận xét',
    confirm: 'Xác nhận',
    confirmTransaction: 'Xác nhận giao dịch',
    great: 'Tuyệt vời!',
    loadingTransactionMessage: 'Xác nhận kết nối trong phần mở rộng.',
    maliciousReports: 'Báo cáo độc hại',
    missingConnectedWalletError: 'Không tìm thấy ví kết nối',
    opening: 'Khai mạc',
    proceedAnyway: 'Dù sao đi nữa',
    reason: 'Lý do',
    reasonFraud: 'Gian lận',
    reasonSpam: 'Thư rác',
    reasonOther: 'Khác',
    reasonErrorRequired: 'Vui lòng chọn một lý do từ danh sách',
    redirectMessageTitle: 'Rời khỏi DSCVR',
    redirectMessageDescription:
      'Bạn sắp rời khỏi DSCVR, hãy nhấp vào tiếp tục để tiếp tục.',
    report: 'Báo cáo',
    reportTransaction: 'Báo cáo giao dịch',
    sendingTransaction: 'Gửi giao dịch',
    transactionGenericError: 'Giao dịch xử lý lỗi',
    transactionNotFoundError:
      'Transaction successful but blockchain timed out. Please check Transaction ID to verify. This may take a few minutes',
    transactionId: 'ID giao dịch',
    transactions: 'giao dịch',
    transactionSuccessMessage: 'Làm thế nào điều này đi?',
    transactionSuccessTitle: 'Giao dịch thành công!',
    transactionReportedTitle: 'Giao dịch này đã được báo cáo',
    transactionReportedMessage:
      'Giao dịch này đã được gắn cờ bởi những người dùng khác có khả năng độc hại.DSCVR khuyên bạn nên hủy giao dịch này.',
    transactionReportTitle: 'Báo cáo giao dịch',
    transactionWarning: 'Cảnh báo giao dịch',
  },
  twitter: {
    oauthError:
      'Ối! Đã xảy ra lỗi khi liên kết Tài khoản X của bạn. Vui lòng thử lại sau',
    unlinkError: 'Ối! Đã xảy ra lỗi khi bỏ liên kết Tài khoản X của bạn',
    unpairedSocialPairingText:
      'Ghép nối tài khoản X của bạn để thêm tên người dùng vào hồ sơ của bạn',
    oauthSuccess: 'Tài khoản X của bạn đã được liên kết thành công',
    unlinkSuccess: 'Tài khoản X của bạn đã được bỏ liên kết thành công',
  },
  nftMarketplace: {
    buy: 'Mua',
    buyNow: 'Mua ngay',
    sold: 'Đã bán',
    price: 'Giá',
    royalty: 'Hoàng gia',
    takerFee: 'Phí taker',
    tensor: { viewOnTensor: 'Xem trên Tensor', buyOnTensor: 'Mua trên Tensor' },
    magicEden: {
      viewOnMagicEden: 'Xem trên Magic Eden',
      buyOnMagicEden: 'Mua trên Magic Eden',
    },
  },
  youMightLike: 'Bạn có thể thích',
  verification: {
    title: 'Được xác minh',
    subtitle:
      'Chúng tôi sẽ gửi cho bạn một email xác minh. Nhấp vào liên kết trong email và quay lại DSCVR.',
    resendTitle: 'Đã gửi!',
    resendSubtitle:
      'Hãy quay lại DSCVR sau khi xác minh email của bạn. Để chọn tham gia nhận thông báo theo chuỗi, hãy nhấp vào biểu tượng chuỗi và chọn “Nhận thông báo”.',
    email: {
      form: {
        errors: {
          invalid: 'Vui lòng nhập email hợp lệ',
          required: 'Địa chỉ e-mail là bắt buộc',
        },
      },
      buttons: {
        sendEmail: 'Gửi email',
        verify: 'Xác minh',
        sent: 'Đã gửi!',
        resend: 'Gửi lại',
        verified: 'Đã xác minh',
      },
      resendExcerpt:
        'Có thể mất vài phút để nhận được email của bạn. Kiểm tra thư rác đề phòng.',
      notifications: {
        successTitle: 'Bạn đã được xác minh!',
        successDescription: 'Cảm ơn bạn đã xác thực email.',
        errorTitle: 'Lỗi khi xác minh email',
        errorDescription:
          'Hãy đảm bảo sử dụng email xác minh mới nhất hoặc gửi lại email mới.',
      },
      accountEmailUpdated: 'Email đã được cập nhật thành công',
      emailSuccessfullySent: 'Gửi email xác minh',
      emailNotSent: 'Gửi email xác minh không thành công: ',
      errors: {
        tooManyRequests: 'Quá nhiều yêu cầu',
        unknownError: 'Lỗi không xác định',
      },
    },
  },
  redirecting: 'Đang chuyển hướng',
  matricaLinkedText: 'Không liên kết',
  matricaUnlinkedText: 'Đã liên kết',
  welcome: 'Chào mừng',
};
