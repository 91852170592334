import { computed, type ComputedRef } from 'vue';
import type { PortalView } from 'dfx/edge/edge.did';
import { buildMetaTitle } from '@/utils';
import { useSeoMeta } from '@unhead/vue';

export function useMetaPortal(portal: ComputedRef<PortalView | undefined>) {
  const title = computed(() =>
    portal.value?.name ? buildMetaTitle(portal.value.name) : '',
  );
  const description = computed(() => {
    return `${portal.value?.description}  w/ ${Number(
      portal.value?.member_count,
    )} member.`;
  });
  const imageUrl = computed(() =>
    portal.value
      ? portal.value.info[0]?.cover_photo[0] &&
        portal.value.info[0]?.cover_photo[0]?.length > 0
        ? portal.value.info[0].cover_photo[0]
        : portal.value.icon_url.length > 0
        ? portal.value.icon_url
        : ''
      : '',
  );

  useSeoMeta({
    title,
    description,
    ogDescription: description,
    twitterDescription: description,
    ogType: 'website',
    ogTitle: title,
    twitterTitle: title,
    twitterSite: 'https://dscvr.one',
    twitterCard: 'summary_large_image',
    ogImage: imageUrl,
    twitterImage: imageUrl,
  });
}
