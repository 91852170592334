import { config } from '@/shared/lib';
import { client } from '@/utils/dfinity';
import { callWithRetry } from '@/utils/general';
import type { Principal } from '@dfinity/principal';
import type {
  ActionResultUser,
  ActionResultUserSearch,
  ActionResultUserSelf,
  CreateICPUser,
  GetGlobalLiveDataResult,
  SetUserSettingsResult,
  SocialProfile,
  UpdateUsername,
  UserFollowPaged,
  UserListPaged,
  UserPlatformPair,
  UserPlatformPairKind,
  UserSearchQuery,
  UserSettingsView,
  UserWallet,
} from 'dfx/edge/edge.did';

export default {
  current_user() {
    return callWithRetry(() => {
      if (client.edgeActor) {
        return client.edgeActor.get_self();
      }
      throw new Error('Edge Actor is not defined');
    }) as Promise<ActionResultUserSelf>;
  },
  get_user_followers(query: { username: string; page: UserListPaged }) {
    if (client.edgeActor) {
      return client.edgeActor.get_user_followers(
        query.username,
        query.page,
      ) as Promise<UserFollowPaged>;
    }
    throw new Error('Actor is not defined');
  },
  remove_follower(userId: Principal) {
    if (client.actor) {
      return client.actor.remove_follower(userId) as Promise<ActionResultUser>;
    }
    throw new Error('Actor is not defined');
  },
  get_user_following(query: { username: string; page: UserListPaged }) {
    if (client.actor) {
      return client.actor.get_user_following(
        query.username,
        query.page,
      ) as Promise<UserFollowPaged>;
    }
    throw new Error('Actor is not defined');
  },
  get_user_blocked(query: { page: number }) {
    if (client.actor) {
      return client.actor.get_user_blocked(
        query.page,
      ) as Promise<UserFollowPaged>;
    }
    throw new Error('Actor is not defined');
  },
  get_own_id() {
    if (client.actor) {
      return client.actor.get_own_id() as Promise<Principal>;
    }
    throw new Error('Actor is not defined');
  },
  get_user(username: string) {
    return callWithRetry(() => {
      if (client.actor) {
        return client.actor.get_user(username);
      }
      throw new Error('Actor is not defined');
    }) as Promise<ActionResultUser>;
  },
  get_user_by_principal(principal: string) {
    return callWithRetry(() => {
      if (client.actor) {
        return client.actor.get_user_by_principal(principal);
      }
      throw new Error('Actor is not defined');
    }) as Promise<ActionResultUser>;
  },
  read_notifications() {
    if (client.actor) {
      return callWithRetry(() => {
        return client.actor?.read_notifications();
      }) as Promise<[]>;
    }
    throw new Error('Actor is not defined');
  },
  update_username(username: UpdateUsername) {
    if (client.actor) {
      return client.actor.update_username(
        username,
      ) as Promise<ActionResultUserSelf>;
    }
    throw new Error('Actor is not defined');
  },
  unpair_phone() {
    if (client.actor) {
      return client.actor.unpair_phone() as Promise<
        { Ok: boolean } | { Err: string }
      >;
    }
    throw new Error('Actor is not defined');
  },
  async create_icp_user(request: CreateICPUser) {
    if (client.actor) {
      // If we're using a hardcoded captcha then create the token and have it match the principal
      if (config.AUTH_TOKEN_BYPASS != null) {
        console.log('Creating captcha token');
        request.auth_token = client.getPrincipal().toText();
        await client.actor.create_auth_token(
          config.AUTH_TOKEN_BYPASS,
          request.auth_token,
          '',
        );
      }

      return (await client.actor.create_icp_user(request)) as ActionResultUser;
    }
    throw new Error('Actor is not defined');
  },
  search_users(query: UserSearchQuery) {
    if (client.edgeActor) {
      return client.edgeActor.search_users(
        query,
      ) as Promise<ActionResultUserSearch>;
    }
    throw new Error('Edge actor is not defined');
  },
  user_search(query: [string]) {
    if (client.edgeActor) {
      return client.edgeActor.user_search(query);
    }
    throw new Error('Edge actor is not defined');
  },
  setSettings(settings: UserSettingsView) {
    if (client.actor) {
      return client.actor.user_set_settings_v2(
        settings,
      ) as Promise<SetUserSettingsResult>;
    }
    throw new Error('Actor is not defined');
  },
  getGlobalLiveData() {
    if (client.edgeActor) {
      return client.edgeActor.get_global_live_data([
        false,
      ]) as Promise<GetGlobalLiveDataResult>;
    }
    throw new Error('Actor is not defined');
  },
  update_social_presence(socialPresence: SocialProfile[]) {
    if (client.actor) {
      return callWithRetry(() => {
        return client.actor?.update_social_presence(
          socialPresence,
        ) as Promise<ActionResultUserSelf>;
      });
    }
    throw new Error('Actor is not defined');
  },
  get_paired_wallets(): Promise<UserWallet[]> {
    if (client.actor) {
      return callWithRetry(() => {
        return client.actor?.get_paired_wallets() as Promise<UserWallet[]>;
      });
    }
    throw new Error('Edge actor is not defined');
  },
  get_paired_platforms(): Promise<UserPlatformPair[]> {
    if (client.edgeActor) {
      return callWithRetry(() => {
        return client.edgeActor?.get_paired_platforms() as Promise<
          UserPlatformPair[]
        >;
      });
    }
    throw new Error('Edge actor is not defined');
  },
  unpair_platform(platform: UserPlatformPairKind) {
    if (client.actor) {
      return callWithRetry(() => {
        return client.actor?.unpair_platform(platform) as Promise<
          UserPlatformPair[]
        >;
      });
    }
    throw new Error('Actor is not defined');
  },
};
