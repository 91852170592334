<script setup lang="ts">
  import { computed } from 'vue';
  import PostBodySummary from './post-body-summary/PostBodySummary.vue';
  import PostBody from './PostBody.vue';
  import { CardGuard } from '@/components/shared/card-guard';
  import { usePost } from '@/entities/post';
  import type { TranslatedContentView } from '@/shared/model';

  const props = defineProps<{
    content: TranslatedContentView;
  }>();

  defineEmits<{
    (e: 'clicked-post'): void;
  }>();

  const { isPostDetailPage, revealedNSFWContent, revealNSFWContent } =
    usePost();

  const isNSFW = computed(() => {
    return props.content.is_nsfw;
  });

  const postGuardComponent = computed(() => (isNSFW.value ? CardGuard : 'div'));

  const reveal = () => {
    revealNSFWContent(props.content.id);
  };

  const isRevealed = computed(() => {
    const id = props.content.id;
    return revealedNSFWContent.value.includes(id);
  });

  const isComment = computed(() => {
    return props.content.content_type === 'comment';
  });

  const body = computed(() => props.content.body);
  const portal = computed(() => props.content.portal);
  const translatedBody = computed(() => props.content.translatedBody);
</script>

<template>
  <component
    :is="postGuardComponent"
    :guarded="!isRevealed"
    @update:guarded="reveal"
  >
    <div class="relative">
      <post-body-summary
        v-if="!isComment && !isPostDetailPage"
        :body="translatedBody ? translatedBody : body"
        :portal="portal"
        @read-more="$emit('clicked-post')"
      />
      <post-body
        v-else
        :body="translatedBody ? translatedBody : body"
        :portal="portal"
      />
    </div>
  </component>
</template>
