import {
  useRouter,
  useRoute,
  type RouteLocationRaw,
  type RouteLocationNormalizedLoaded,
} from 'vue-router';
import type { ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useGtm } from '@gtm-support/vue-gtm';
import { ActionTypes, MutationTypes } from '@/store';
import {
  useDevice,
  useDialog,
  useToast,
  useAuthStore,
  type AccountType,
  type AuthContext,
  type AuthForm,
  type AuthFormType,
} from '@/shared/model';
import { client, trackEvent } from '@/utils';
import { config } from '@/shared/lib';
import { fetchMedia } from '@/shared/lib';
import { A_WEEK, DSCVR_STATIC_ASSETS_CDN_URL, HOME } from '@/common';
import {
  NFID_AUTH_URL,
  AUTH_ENTITY_PARAM,
  AUTH_RESPONSE_PARAM,
  InterruptPayloadKind,
  IDENTITY_AUTH_ROUTES,
} from '../../lib/constants';
import type { ActionResultUserSelf, CreateICPUser } from 'dfx/edge/edge.did';
import type { InterruptPayload } from '../../types';
import type { AuthClient } from '@/auth-client';
import { useGetUserByWalletAddressQuery } from '../../../wallets';
import UseMobileWalletAppForm from '../../components/forms/UseMobileWalletAppForm.vue';
import LoginSignUpForm from '../../components/forms/LoginSignUpForm.vue';
import RegistrationForm from '../../components/forms/RegistrationForm.vue';
import DetectionForm from '../../components/forms/DetectionForm.vue';
import AccountNotFoundForm from '../../components/forms/AccountNotFoundForm.vue';
import UnsuccessfulPairingForm from '../../components/forms/UnsuccessfulPairingForm.vue';
import { ImageHeader } from '@/shared/ui/base-dialog';
import { useCreateReferredUserMutation } from '../../api/use-create-referred-user.mutation';
import { useCreateIcpUserMutation } from '../../api/use-create-icp-user.mutation';
import { useVerifyUserMutation } from '../../../wallets';

type RedirectAuthEntity = {
  form: AuthForm;
  context: AuthContext;
  accountType: AccountType;
  query?: RouteLocationNormalizedLoaded['query'] | null;
};

export const useAuth = () => {
  const walletAddress = ref<string>();
  const gtm = useGtm();
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const { showToast } = useToast();
  const { dialogDefaults, openConfiguredDialog, closeDialog } = useDialog();
  const authStore = useAuthStore();
  const { authEntity } = storeToRefs(authStore);
  const { userAgent, isEmbedded: isEmbeddedBrowser } = useDevice();
  const { refetch: fetchUserByWalletAddress } =
    useGetUserByWalletAddressQuery(walletAddress);
  const { mutate: createReferredUserMutation } =
    useCreateReferredUserMutation();
  const { mutate: createIcpUserMutation } = useCreateIcpUserMutation();
  const { mutate: verifyUserMutation } = useVerifyUserMutation();

  const IS_DERIVATION_ORIGIN =
    import.meta.env.VITE_ENABLE_PROD_LOGIN !== 'true' ||
    window.location.href.includes('dscvr.ic0.app') ||
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1' ||
    window.location.hostname === '0.0.0.0' ||
    window.location.port === '9000' ||
    (window.location.hostname.includes('onrender.com') &&
      window.location.hostname != 'dscvr-frontend-staging.onrender.com' &&
      window.location.hostname != 'dscvr-frontend-beta-staging.onrender.com');

  const loginRoute: ComputedRef<RouteLocationNormalizedLoaded> = computed(
    () => store.getters['auth/loginRoute'],
  );

  const isInternetIdentityOrOther = computed(
    () =>
      authEntity.value.accountType === 'internet identity' ||
      authEntity.value.accountType === 'other',
  );

  const authManager = computed(() => {
    const contentClasses = `${dialogDefaults.dialog.contentClasses} flex flex-col max-w-[438px] pt-6 pb-8 px-8`;

    return {
      'account-not-found': {
        content: {
          component: AccountNotFoundForm,
        },
        dialog: {
          contentClasses: `${dialogDefaults.dialog.contentClasses} flex flex-col max-w-[438px] pt-6 pb-8 px-8`,
          showCloseButton: false,
        },
        drawer: {
          customClasses: `${dialogDefaults.drawer.customClasses} p-6`,
          showHeader: false,
        },
      },
      detection: {
        content: {
          component: DetectionForm,
        },
        dialog: {
          contentClasses,
          showCloseButton: false,
        },
        drawer: {
          customClasses: `${dialogDefaults.drawer.customClasses} p-6`,
          showHeader: false,
        },
      },
      'login-signup': {
        content: {
          component: LoginSignUpForm,
        },
        dialog: {
          contentClasses,
        },
        drawer: {
          customClasses: `${dialogDefaults.drawer.customClasses} px-6 pb-8`,
        },
        emit: () => {
          trackEvent(
            'user_action',
            authEntity.value.form.name,
            `closed_${authEntity.value.form.name}_dialog`,
          );
          hideLoading();
        },
      },
      registration: {
        content: {
          component: RegistrationForm,
        },
        header: {
          component: ImageHeader,
          props: {
            src: fetchMedia(
              `${DSCVR_STATIC_ASSETS_CDN_URL}/registration/registration-dialog-banner.png`,
            ),
            alt: 'Welcome',
          },
          emits: {
            close: closeDialog,
          },
        },
        dialog: {
          contentClasses: `${dialogDefaults.dialog.contentClasses} flex flex-col max-w-[480px] overflow-hidden h-[calc(100vh-5rem)]`,
          closeOnClickOutside: false,
        },
        drawer: {
          customClasses: dialogDefaults.drawer.customClasses,
          closeOnClickOutside: false,
        },
      },
      'unsuccessful-pairing': {
        content: {
          component: UnsuccessfulPairingForm,
        },
        header: {
          component: ImageHeader,
          slots: {
            media: {
              template: `<img src="${fetchMedia(
                `${DSCVR_STATIC_ASSETS_CDN_URL}/solana/space-travel.gif`,
              )}" alt="Pairing unsuccessful" class="size-64 mx-auto" />`,
            },
          },
          emits: {
            close: closeDialog,
          },
        },
        dialog: {
          contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-[500px] overflow-hidden`,
        },
        drawer: {
          customClasses: `${dialogDefaults.drawer.customClasses} overflow-hidden`,
        },
        emit: () =>
          trackEvent(
            'user_action',
            'signup',
            'closed_unsuccessful_pairing_dialog',
          ),
      },
      'use-mobile-wallet-app': {
        content: {
          component: UseMobileWalletAppForm,
        },
        header: {
          component: ImageHeader,
          props: {
            src: fetchMedia(
              `${DSCVR_STATIC_ASSETS_CDN_URL}/solana/use-mobile-waller-app.gif`,
            ),
            alt: 'Use mobile wallet app',
          },
          emits: {
            close: closeDialog,
          },
        },
        dialog: {
          closeOnClickOutside: false,
          contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-[480px] overflow-hidden`,
        },
        drawer: {
          customClasses: `${dialogDefaults.drawer.customClasses} overflow-hidden`,
        },
        emit: hideLoading,
      },
    }[authEntity.value.form.name];
  });

  const updateAuthManager = (context: AuthContext, form: AuthForm) => {
    authEntity.value.visible = true;
    authEntity.value.context = context;
    authEntity.value.form = form;
    if (context === 'dialog') {
      openConfiguredDialog(authManager.value);
    }
  };

  const showLoading = (isLoading: boolean) => {
    store.commit(`auth/${ActionTypes.SET_IS_LOADING}`, isLoading);
  };

  const hideLoading = () => {
    store.dispatch(`auth/${ActionTypes.SET_IS_LOADING}`, false);
  };

  const getIdentityProvider = () => {
    let url = NFID_AUTH_URL;
    if (IDENTITY_AUTH_ROUTES.includes(authEntity.value.accountType!)) {
      url = `${config.IDENTITY_URL}/${authEntity.value.accountType}`;
    } else if (authEntity.value.accountType === 'credentials') {
      url = `${config.IDENTITY_URL}/${authEntity.value.accountType}/${authEntity.value.form.type}`;
      // We're passing the username via the URL to
      // pre-fill the username field in the signup form
      if (
        authEntity.value.form.type &&
        ['signup', 'login'].includes(authEntity.value.form.type) &&
        authEntity.value.username
      ) {
        url += `?username=${authEntity.value.username}`;
      }
    }
    return url;
  };

  const onAuthorizeRedirect = async (
    authClient: AuthClient,
    authEntityString: string,
  ) => {
    const redirectResponse = authClient.redirectResponse();

    if (!redirectResponse) {
      onAuthorizeError('redirectResponse is null');
      return;
    }

    switch (redirectResponse.kind) {
      case 'authorize-client-failure':
        onAuthorizeError(redirectResponse.text);
        return;
      case 'authorize-client-success':
        try {
          const authEntityObj = JSON.parse(
            decodeURIComponent(authEntityString),
          ) as RedirectAuthEntity;
          authEntity.value = {
            ...authEntity.value,
            ...authEntityObj,
          };

          store.dispatch(`auth/${ActionTypes.SET_LOGIN_ROUTE}`, {
            name: HOME,
            query: authEntityObj.query,
          });

          await onAuthorizeSuccess(authClient);
        } catch (e) {
          onAuthorizeError(`${e}`);
        }
        return;
      case 'authorize-client-interrupt':
        onAuthorizeInterrupt(redirectResponse.payload as InterruptPayload);
        return;
    }
  };

  const onAuthorizeSuccess = async (authClient: AuthClient) => {
    const identity = authClient?.getIdentity();
    const accountType = authEntity.value.accountType!;
    trackEvent('user_action', 'login', accountType);
    trackEvent(
      'user_agent',
      userAgent.value,
      identity.getPrincipal().toString(),
    );
    await client.setIdentity(identity);
    closeDialog();
    store
      .dispatch(`auth/${ActionTypes.GET_SELF}`, { hasLoading: true })
      .then((response: ActionResultUserSelf) => {
        // result will be empty if user is not registered and it didn't sign up through DSCVR identity app
        // result will  not be empty but the user name will be empty if the user is not registered and signed up through DSCVR identity app
        const result = response.result;
        // User is known
        if (result.length > 0) {
          const user = result[0]!;
          // User is provisional
          if (user.is_provisional && user.identity.length > 0) {
            const identity = user.identity[0]!;
            if ('Username' in identity) {
              authEntity.value.username = identity.Username;
            } else if ('Email' in identity) {
              authEntity.value.email = identity.Email.email;
            }
          }
          // User is not provisional
          else {
            // We replace the route if dialog, but let the form take care of
            // the routing on login for specialized behavior.
            if (authEntity.value.context === 'dialog') {
              const query = { ...loginRoute.value.query };
              if (AUTH_RESPONSE_PARAM in query)
                delete query[AUTH_RESPONSE_PARAM];
              if (AUTH_ENTITY_PARAM in query) delete query[AUTH_ENTITY_PARAM];
              router.replace({
                name: loginRoute.value.name!,
                params: loginRoute.value.params,
                query,
              });
            }
            authStore.setLastLoginOption(accountType);
            verifyUserMutation();
            return;
          }
        }
        // User is not found (using II or other)
        if (isInternetIdentityOrOther.value) {
          updateAuthManager(authEntity.value.context, {
            name: 'account-not-found',
          });
          return;
        }
        // User is not known
        updateAuthManager(authEntity.value.context, { name: 'registration' });
      });
  };

  const onAuthorizeError = (err: string | undefined) => {
    hideLoading();
    closeDialog();
    showToast({
      type: 'error',
      title: 'Authentication not authorized',
      durationSeconds: 3,
    });
  };

  const onAuthorizeInterrupt = async (payload: InterruptPayload) => {
    hideLoading();
    const interruptPayload = payload;
    switch (interruptPayload.kind) {
      case InterruptPayloadKind.INTERRUPT_PASSWORD_RESET:
        showToast({
          type: 'success',
          title: `An email has been sent with a link to reset your password`,
          durationSeconds: 5,
        });
        break;
      case InterruptPayloadKind.INTERRUPT_IDENTITY_REDIRECT:
        authEntity.value.username = interruptPayload.username;
        authEntity.value.identityType = interruptPayload.identity_type;
        updateAuthManager(authEntity.value.context, { name: 'detection' });
        break;
      case InterruptPayloadKind.INTERRUPT_WALLET_ALREADY_PAIRED:
        walletAddress.value = interruptPayload.address;
        const { data: alreadyPairedWallet } = await fetchUserByWalletAddress();

        if (alreadyPairedWallet) {
          authEntity.value.username = alreadyPairedWallet.owner.username;
          authEntity.value.identityType = 'username';
          trackEvent(
            'user_action',
            'signup',
            'opened_unsucessful_pairing_dialog',
          );
          updateAuthManager(authEntity.value.context, {
            name: 'unsuccessful-pairing',
          });
        }
        break;
    }
  };

  const getCallbackUrl = () => {
    const url = new URL(window.location.href);
    const { form, context, accountType } = authEntity.value;
    url.searchParams.set(
      AUTH_ENTITY_PARAM,
      encodeURIComponent(
        JSON.stringify({
          form,
          context,
          accountType,
          query: loginRoute.value.query,
        }),
      ),
    );
    return url.toString();
  };

  /**
   * This function CANNOT be async or inside of a promise, since authClient?.login calls window.open which will be blocked in safari if inside a promise
   */
  const login = (accountType: AccountType) => {
    authEntity.value.accountType = accountType;
    const isInternetIdentity =
      authEntity.value.accountType === 'internet identity';

    store.dispatch(`auth/${ActionTypes.SET_IS_LOADING}`, true);
    store.dispatch(
      `auth/${ActionTypes.SET_LOGIN_ROUTE}`,
      router.currentRoute.value,
    );

    const derivationOrigin = IS_DERIVATION_ORIGIN
      ? undefined
      : 'https://h5aet-waaaa-aaaab-qaamq-cai.raw.ic0.app';

    client.getOrCreateAuthClient().then((authClient: AuthClient) => {
      authClient?.login({
        maxTimeToLive: isInternetIdentity
          ? BigInt(A_WEEK) * BigInt(3)
          : BigInt(A_WEEK),
        derivationOrigin,
        ...(!isInternetIdentity && {
          windowOpenerFeatures:
            `left=${window.screen.width / 2 - 525 / 2}, ` +
            `top=${window.screen.height / 2 - 705 / 2},` +
            `toolbar=0,location=0,menubar=0,width=525,height=705`,
          identityProvider: getIdentityProvider(),
          redirectContext: isEmbeddedBrowser.value
            ? {
                url: getCallbackUrl(),
                param: AUTH_RESPONSE_PARAM,
              }
            : undefined,
        }),
        onSuccess: async () => {
          await onAuthorizeSuccess(authClient);
        },
        onError: onAuthorizeError,
        onInterrupt: (payload: unknown) =>
          onAuthorizeInterrupt(payload as InterruptPayload),
      });
    });
  };

  const createUser = (params: CreateICPUser) => {
    showLoading(true);
    createIcpUserMutation(params, {
      onSuccess: (data) => {
        if (data.status === 'happy') {
          const accountType = authEntity.value.accountType;
          gtm?.trackEvent({ event: 'Account Created' });
          trackEvent('user_action', 'signup', accountType);
          if (accountType) {
            authStore.setLastLoginOption(accountType);
          } else {
            authStore.clearLastLoginOption();
          }
          if (route.query.redirectUrl) {
            if (import.meta.env.PROD) {
              router.push(route.query.redirectUrl as RouteLocationRaw);
            }
          } else if (loginRoute.value) {
            router.push({
              name: loginRoute.value.name!,
              params: loginRoute.value.params,
              query: loginRoute.value.query,
            });
          } else {
            router.push({ name: HOME });
          }
          store.commit(`auth/${MutationTypes.SET_ME}`, data.result[0]);
          store.dispatch(`auth/${ActionTypes.SET_LOGIN_ROUTE}`, null);
          verifyUserMutation();
          closeDialog();
        } else {
          showToast({
            type: 'error',
            title: data.message,
            durationSeconds: 3,
          });
        }
      },
      onError: (error) => {
        showToast({
          type: 'error',
          title: error.message,
          durationSeconds: 3,
        });
      },
      onSettled: () => {
        hideLoading();
      },
    });
  };

  const createReferredUser = (params: CreateICPUser) => {
    showLoading(true);
    createReferredUserMutation(params, {
      onSuccess: (data) => {
        if (data.status !== 'happy') {
          showToast({
            type: 'error',
            title: data.message,
            durationSeconds: 3,
          });
        } else {
          store.commit(`auth/${MutationTypes.SET_ME}`, data.result[0]);
          verifyUserMutation();
        }
      },
      onSettled: () => {
        hideLoading();
      },
    });
  };

  const resetAuthFormValues = () => {
    authEntity.value.username = '';
    authEntity.value.email = '';
    authEntity.value.portal_slug = '';
    authEntity.value.referral_code = '';
    authEntity.value.referring_username = '';
  };

  const resetAuthEntity = () => {
    authEntity.value.identityType = undefined;
    authEntity.value.username = undefined;
    authEntity.value.accountType = undefined;
  };

  const showLoginSignUpDialog = (type: AuthFormType = 'login') => {
    resetAuthFormValues();
    updateAuthManager(authEntity.value.context, { name: 'login-signup', type });
  };

  return {
    authEntity,
    authManager,
    createUser,
    createReferredUser,
    closeDialog,
    hideLoading,
    login,
    showLoginSignUpDialog,
    isInternetIdentityOrOther,
    onAuthorizeRedirect,
    resetAuthEntity,
    resetAuthFormValues,
    updateAuthManager,
  };
};
