<script setup lang="ts">
  import { computed, onBeforeMount, ref, toRef } from 'vue';
  import { useRoute } from 'vue-router';
  import { fetchMedia, getH1Matches } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
  import CommentForm from '@/components/forms/CommentForm.vue';
  import RefreshButton from '@/components/buttons/RefreshButton.vue';
  import { parseSocietyRsErrorType } from '@/shared/lib';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { PermissionFlags, hasPermission, trackEvent } from '@/utils';
  import { Empty } from '@/shared/ui/empty';
  import { useCommentFeed } from '@/entities/comment';
  import { CommentFeed, Post, SortBy } from '@/features/post';
  import { useUser } from '@/entities/user';
  import { useMetaPost, useGetContentByIdQuery } from '@/entities/post';
  import type { Ref } from 'vue';
  import type { ContentSort } from 'dfx/edge/edge.did';
  import { useFeed } from '@/shared/model';
  import { useI18n } from 'vue-i18n';

  const props = defineProps<{
    id: string;
    title?: string;
  }>();

  const contentId = toRef(props, 'id');
  const sortValue: Ref<ContentSort> = ref({ Default: null });
  const isCommenting = ref(false);
  const commentFormRef = ref<InstanceType<typeof CommentForm> | null>(null);
  const commentListRef = ref<InstanceType<typeof CommentFeed> | null>(null);

  const { data, isLoading } = useGetContentByIdQuery(contentId);
  const { currentUser } = useUser();
  const route = useRoute();
  const { t } = useI18n({ useScope: 'global' });

  const {
    onDeleteContent,
    onEditContent,
    onGoodbye,
    onPinContent,
    onPinContentToProfile,
    onPollVote,
    onRemoveContent,
    onSetNsfw,
    onSetRank,
    onTranslateContent,
  } = useFeed();

  const { createFeedComment } = useCommentFeed();

  const currentPost = computed(() => data.value?.result?.[0]);
  const canComment = computed(() => {
    return (
      currentPost.value &&
      hasPermission(
        currentPost.value.perm,
        PermissionFlags.CREATE_CONTENT_COMMENT,
      )
    );
  });

  const isUnauthorizedPost = computed(() => {
    return (
      data.value &&
      parseSocietyRsErrorType(data.value.error_code) === 'Unauthorized'
    );
  });

  const hasH1TagInBody = computed(() => {
    if (!currentPost.value?.body) return;
    return getH1Matches(currentPost.value.body).length > 0;
  });

  const isPrerender = () => {
    return window.location.href.includes('prerender=true');
  };

  const createCommentSubmit = async (unstoredBody: string) => {
    isCommenting.value = true;
    if (currentPost.value === undefined) return;
    createFeedComment(
      currentPost.value.portal.id,
      BigInt(props.id),
      unstoredBody,
    );
    commentFormRef.value?.reset();
    isCommenting.value = false;
  };

  const getContentSort = (sort: string): ContentSort => {
    switch (sort.toLowerCase()) {
      case 'default':
        return { Default: null };
      case 'newest':
        return { New: null };
      case 'live':
        return { Live: null };
      case 'op':
        return { OP: null };
      case 'oldest':
        return { Old: null };
      case 'verified_pfp':
        return { VerifiedPfp: null };
      default:
        break;
    }
    return { Default: null };
  };

  const sortSelected = (sort: string) => {
    sortValue.value = getContentSort(sort);
  };

  const refreshComments = () => {
    commentListRef.value?.resetFeed();
  };

  const targetCommentId = computed(() => {
    const hash = route.hash
      ? route.hash.substring(1)
      : route.params.comment?.toString();
    if (hash) {
      try {
        return BigInt(hash);
      } catch (e) {
        console.error(`Target Comment ID Error: ${e}`);
      }
    }
    return undefined;
  });

  onBeforeMount(() => {
    trackEvent('post view', 'details page', 'default');
  });

  const { ogTitle } = useMetaPost(currentPost);
</script>

<template>
  <section class="content">
    <div class="w-full">
      <template v-if="currentPost">
        <h1 class="hidden" v-if="!currentPost.title && !hasH1TagInBody">
          {{ ogTitle }}
        </h1>
        <post
          class="bg-gray-950"
          :post="currentPost"
          @translate-content="onTranslateContent"
          @delete-content="onDeleteContent"
          @remove-content="onRemoveContent"
          @edit-content="onEditContent"
          @pin-content="onPinContent"
          @pin-content-to-profile="onPinContentToProfile"
          @set-nsfw="onSetNsfw"
          @goodbye="onGoodbye"
          @set-rank="onSetRank"
          @on-poll-vote="onPollVote"
        />
        <div class="text-2xl font-semibold text-white mt-2">
          {{ t('comments') }}
        </div>

        <div class="pb-2 mt-3 mb-10 bg-gray-950 md:rounded-xl">
          <div class="relative">
            <div class="mx-4">
              <overlay-loader v-if="isCommenting" />
              <div class="flex items-start w-full pt-5 mb-2">
                <template v-if="canComment">
                  <user-avatar
                    v-if="currentUser"
                    :item="currentUser"
                    size="w-12 h-12"
                    class="mr-2"
                  />
                  <comment-form
                    ref="commentFormRef"
                    :autofocus="Boolean(route.query.autofocus)"
                    @submit="createCommentSubmit"
                  />
                </template>
              </div>
            </div>
            <div
              class="flex items-center justify-between mx-4"
              :class="{
                'py-6': canComment,
                'pb-6': !canComment,
              }"
            >
              <sort-by @sort-selected="sortSelected($event.value)" />
              <refresh-button @refresh="refreshComments" />
            </div>
          </div>
          <comment-feed
            v-if="currentPost && !isPrerender()"
            ref="commentListRef"
            :post-id="currentPost.id"
            :sort-by="sortValue"
            :target-comment-id="targetCommentId"
          />
        </div>
      </template>
      <empty v-else-if="isUnauthorizedPost">
        <template #image>
          <img
            :src="
              fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/post/closed-jar.gif`)
            "
            alt="Unauthorized"
            class="object-contain size-48"
          />
        </template>
        <template #title>
          {{ $t('postFeature.postRestricted') }}
        </template>
      </empty>
      <empty v-else-if="!isLoading">
        <template #title>
          {{ $t('postFeature.postDoNotExist') }}
        </template>
        <template #description>
          {{ $t('postFeature.originalPostDoNotExist') }}
        </template>
      </empty>
    </div>
  </section>
</template>
