<script lang="ts" setup>
  import { computed, watch, toRef, onMounted } from 'vue';
  import { useHead } from '@unhead/vue';
  import { PostList } from '@/widgets/post-list';
  import { FeedFilter, useFeedFilter } from '@/widgets/feed-filter';
  import { useUser } from '@/entities/user';
  import { useChainBehavior } from '@/entities/user-setting';
  import { SolanaBanner } from '@/widgets/solana-banner';
  import CreatePostBar from '@/components/bars/CreatePostBar.vue';
  import {
    useFeed,
    forYouFeedKey,
    followingFeedKey,
    allDscvrFeedKey,
    listContentKey,
    portalsFeedKey,
    userPostsListKey,
    type FeedOptions,
  } from '@/shared/model';
  import { ALL, FOLLOW, HOME, PORTALS, USER_CONTENT } from '@/common';
  import { useRoute, useRouter } from 'vue-router';
  import { useValidateToken } from '@/features/user-settings';
  import { useAuth } from '@/entities/auth';
  import { useStreak } from '@/entities/streak';
  import { LootboxBanner } from '@/features/onboarding';
  import { usePostDialog } from '@/features/post';
  import { config } from '@/shared/lib';

  const props = defineProps<{ username?: string }>();

  const username = toRef(() => props.username);

  const route = useRoute();
  const router = useRouter();
  const { showLoginSignUpDialog } = useAuth();
  const { isLoggedIn, currentUser } = useUser();
  const { showDailyStreakDialog } = useStreak();
  const { contentPreset } = useChainBehavior();
  const { dateFilterQueryParameter, sortFilterQueryParameter } =
    useFeedFilter();
  const { openCreatePostDialog } = usePostDialog();

  const isUserContentPage = computed(() => route.name === USER_CONTENT);
  const feedOptions = computed<FeedOptions | undefined>(() => {
    return {
      [ALL]: {
        key: allDscvrFeedKey,
        type: listContentKey,
        filter: 'all',
        chain_filter: contentPreset.value,
      },
      ...(isLoggedIn.value && {
        [FOLLOW]: {
          key: followingFeedKey,
          type: followingFeedKey,
          algorithm: 'MyFeed',
          filter: 'follow',
          chain_filter: contentPreset.value,
          sort: sortFilterQueryParameter.value,
        },
      }),
      [HOME]: {
        key: forYouFeedKey,
        type: forYouFeedKey,
        algorithm: 'ForYou',
        filter: 'hot',
        chain_filter: contentPreset.value,
        max_age: [BigInt(2 * 30 * 24 * 60 * 60)],
      },
      ...(isLoggedIn.value && {
        [PORTALS]: {
          key: portalsFeedKey,
          type: listContentKey,
          filter: 'hot',
          chain_filter: contentPreset.value,
          max_age: [BigInt(2 * 30 * 24 * 60 * 60)],
          sort: sortFilterQueryParameter.value,
        },
      }),
      [USER_CONTENT]: {
        key: userPostsListKey,
        type: userPostsListKey,
        filter: 'user',
        username,
      },
    }[route.name as string] as FeedOptions;
  });

  const meta = computed(() => {
    return {
      [ALL]: {
        title: 'My DSCVR Search - DSCVR',
      },
      ...(isLoggedIn.value && {
        [FOLLOW]: {
          title: 'Who I’m Following/Following - DSCVR',
        },
      }),
      [HOME]: {
        title: 'DSCVR - The Social Home of Web3',
      },
      ...(isLoggedIn.value && {
        [PORTALS]: {
          title: 'Portal Directory - DSCVR',
        },
      }),
      [USER_CONTENT]: {
        title: `${username.value}'s posts - DSCVR`,
      },
    }[route.name as string];
  });

  useHead({
    title: () => meta.value?.title ?? '',
  });

  const {
    isLoading,
    onComment,
    onDeleteContent,
    onEditContent,
    onFetchNextPage,
    onGoodbye,
    onPinContent,
    onPinContentToProfile,
    onPollVote,
    onRemoveContent,
    onSetNsfw,
    onSetRank,
    onTranslateContent,
    personalizedFeedV1Query,
    posts,
  } = useFeed(feedOptions);

  watch(
    [() => route.query.sort, () => route.query.sortDateRange],
    ([sortValue, _]) => {
      if (personalizedFeedV1Query.value) {
        personalizedFeedV1Query.value.query.sort =
          sortFilterQueryParameter.value;
        personalizedFeedV1Query.value.query.max_age =
          sortValue === 'top' && dateFilterQueryParameter.value
            ? [BigInt(dateFilterQueryParameter.value)]
            : [];
      }
    },
    { immediate: true },
  );

  const { handleTokenValidation } = useValidateToken();

  watch(
    [
      () => isLoggedIn.value,
      () => route.query.username,
      () => route.query.openStreakModal,
      () => route.query.token,
    ],
    async ([loggedIn, username, openStreakModal, token]) => {
      if (route.name === HOME) {
        if (
          !loggedIn &&
          username &&
          (openStreakModal === 'true' || token !== undefined)
        ) {
          showLoginSignUpDialog();
        } else if (
          loggedIn &&
          username !== currentUser.value.username &&
          (openStreakModal === 'true' || token !== undefined)
        ) {
          router.replace({ query: {} });
        } else if (loggedIn && username === currentUser.value.username) {
          // Handle streak modal
          if (openStreakModal === 'true') {
            showDailyStreakDialog();
            router.replace({ query: {} });
          }
          // Handle email verification
          if (token) {
            await handleTokenValidation(token as string);
            router.replace({ query: {} });
          }
        }
      }
    },
    { immediate: true },
  );

  onMounted(() => {
    if (typeof route.query.post === 'string') {
      openCreatePostDialog(route.query.post);
      router.replace({ query: { ...route.query, post: undefined } });
    }
  });
</script>

<template>
  <section
    class="w-full"
    :class="{
      'md:px-16 lg:px-0 xl:max-w-[calc(100%-19rem)]': isUserContentPage,
    }"
  >
    <feed-filter v-if="!isUserContentPage" />
    <solana-banner v-if="route.name === HOME" />
    <lootbox-banner
      v-if="
        config.ENABLE_ONBOARDING_LOOTBOX && !isLoggedIn && route.name === HOME
      "
    />
    <create-post-bar v-if="isLoggedIn && !isUserContentPage" />
    <post-list
      :posts="posts"
      :is-loading="isLoading"
      @fetch-next-page="onFetchNextPage"
      @translate-content="onTranslateContent"
      @delete-content="onDeleteContent"
      @remove-content="onRemoveContent"
      @edit-content="onEditContent"
      @pin-content="onPinContent"
      @pin-content-to-profile="onPinContentToProfile"
      @set-nsfw="onSetNsfw"
      @goodbye="onGoodbye"
      @set-rank="onSetRank"
      @on-comment="onComment"
      @on-poll-vote="onPollVote"
    />
  </section>
</template>
