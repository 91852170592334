<script setup lang="ts">
  import { onActivated, watch, ref } from 'vue';
  import { useRoute, type RouteLocationRaw } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { useHead } from '@unhead/vue';
  import {
    EXPLORE_PEOPLE_FEATURED,
    EXPLORE_PORTALS,
    EXPLORE_PORTALS_FEATURED,
    EXPLORE_PEOPLE,
  } from '@/common';
  import { AppHeader } from '@/widgets/app-header';
  import {
    ExploreHeader,
    FeaturedPortals,
    FeaturedPeople,
    AllPortals,
    AllPeople,
  } from './components';
  import type { IconName } from '@/shared/ui/base-icon';

  type TabType = 'portals' | 'people' | 'all-portals' | 'all-people';

  interface TabItem {
    key: TabType;
    icon: IconName;
    label: string;
    route: RouteLocationRaw;
    metaTitle: string;
  }

  const route = useRoute();
  const { t } = useI18n({ useScope: 'global' });
  const selectedTab = ref<TabType>('portals');
  const isSearchVisible = ref(true);

  const tabs: TabItem[] = [
    {
      key: 'portals',
      icon: 'outline-portal',
      label: t('portals'),
      route: {
        name: EXPLORE_PORTALS_FEATURED,
      },
      metaTitle: 'Explored featured portals - DSCVR',
    },
    {
      key: 'people',
      icon: 'vuesax-linear-profile-2user',
      label: t('people'),
      route: {
        name: EXPLORE_PEOPLE_FEATURED,
      },
      metaTitle: 'Explore recommended people - DSCVR',
    },
  ];

  useHead({
    title: () =>
      tabs.find((tab) => tab.key === selectedTab.value)?.metaTitle ?? '',
  });

  const loadSelectedTabByRoute = () => {
    if (route.name === EXPLORE_PORTALS) {
      selectedTab.value = 'all-portals';
    } else if (route.name === EXPLORE_PEOPLE) {
      selectedTab.value = 'all-people';
    } else if (route.name === EXPLORE_PORTALS_FEATURED) {
      selectedTab.value = 'portals';
    } else if (route.name === EXPLORE_PEOPLE_FEATURED) {
      selectedTab.value = 'people';
    }
  };

  const selectTab = (key: TabType) => {
    isSearchVisible.value = true;
    selectedTab.value = key;
  };

  watch(
    () => route.fullPath,
    () => {
      isSearchVisible.value = true;
      loadSelectedTabByRoute();
    },
    {
      immediate: true,
    },
  );

  onActivated(() => {
    loadSelectedTabByRoute();
  });
</script>

<template>
  <app-header />
  <explore-header @search-visible="isSearchVisible = $event" />
  <div class="sticky top-0 w-full h-0 z-30">
    <div class="w-full h-18 bg-gray-975"></div>
  </div>
  <div
    v-if="['people', 'portals'].includes(selectedTab)"
    class="flex justify-center gap-7 text-gray-400 text-sm py-8 sticky top-18 bg-gray-975 z-40"
  >
    <base-button
      v-for="tab in tabs"
      :key="tab.key"
      variant="custom"
      rounded="rounded-none"
      class="font-medium text-lg pb-2 border-b-2 border-transparent w-fit mb-4 min-w-24 hover:text-white transition-all duration-300 capitalize cursor-pointer gap-2"
      :class="{ 'text-white border-white': selectedTab === tab.key }"
      :to="tab.route"
      @click="selectTab(tab.key)"
    >
      <base-icon :name="tab.icon" size="w-6 h-6" />
      <span>{{ tab.label }}</span>
    </base-button>
  </div>
  <div class="md:px-8">
    <featured-portals
      v-if="selectedTab === 'portals'"
      :show-search="!isSearchVisible"
    />
    <featured-people
      v-else-if="selectedTab === 'people'"
      :show-search="!isSearchVisible"
    />
    <all-portals
      v-else-if="selectedTab === 'all-portals'"
      :show-search="!isSearchVisible"
    />
    <all-people
      v-else-if="selectedTab === 'all-people'"
      :show-search="!isSearchVisible"
    />
  </div>
</template>
