export default {
  about: 'About',
  add: 'Add',
  addLink: 'Add link',
  addNewNft: 'Add new NFT',
  addRule: 'Add rule',
  addWallet: 'Add wallet',
  active: 'Active',
  airdrops: 'Airdrops',
  all: 'All',
  allPeople: 'All People',
  allPortals: 'All portals',
  allVolume: 'All Vol',
  allowFrames: 'Allow Frames',
  alsoCommented: 'also commented',
  and: 'and',
  andOthers: 'and others',
  access: 'Access',
  accessDescription:
    'Paired Wallets will display here. Solana loot from lootboxes will be sent to your primary wallet.',
  appsAndSocial: 'Apps & Social',
  arcade: {
    creatorSpotlight: 'Creator Spotlight',
    discoverGamingPortals: 'Discover Gaming Portals',
    enjoyGames: 'Enjoy free games or play to win',
    moreGamesComing: 'More games and community leaderboards coming soon.',
    newToArcade: 'New to Arcade',
    owner: 'Owner',
    slideTitle: 'Want to submit your game?',
    slidePrimaryButtonText: 'Apply here',
    slideSecondaryButtonText: 'Learn more',
    welcome: 'Welcome to DSCVR ARCADE',
  },
  auth: {
    subtitle: 'Pushing the boundaries of Web3 social media',
    detectionForm: {
      accountFound: 'Found it!',
      yourAccount: 'Your account',
      wasMadeWith: 'was made with',
      useNonWalletBrowser:
        'Please use a non-wallet browser on your device such as Chrome, Firefox, or Safari to login.',
      clickOneOfTheButtons: 'Click one of the buttons',
      clickTheButton: 'Click the button',
      belowToContinue: 'below to continue.',
      signInWith: 'Sign in with',
      username: 'Username',
    },
    accountNotFound: {
      notFoundWithNFID:
        'Account not found. Please make sure you are using the correct NFID account',
      notFoundWithII:
        'Account not found. Please make sure you are using the correct Internet Identity Anchor',
      signInWithUsername: 'Sign in with Username',
    },
    mobileWalletApp: {
      title: 'Use mobile wallet app',
      description: 'Please log into DSCVR in your mobile wallet app',
    },
    loginForm: {
      byContinuing: 'By continuing, you agree to our',
      termOfService: 'Terms of Service',
      andOur: 'and our',
      privacyPolicy: 'Privacy Policy.',
    },
    registrationForm: {
      createAccount: 'Create account',
    },
  },
  back: 'Back',
  backToProfile: 'Back to profile',
  backToFeeds: 'Back to feeds',
  backToPost: 'Back to post',
  balance: 'Balance',
  ban: 'Ban',
  banMember: 'Ban member',
  block: 'Block',
  blocked: 'Blocked',
  bookmarks: 'Bookmarks',
  browseGames: 'Browse Games',
  canisterPrincipalId: 'Canister principal id',
  canisterIdErrorRequired: 'Canister id is required',
  canisterIdErrorInvalid: 'Invalid canister principal id',
  cancel: 'Cancel',
  canvas: {
    errorLoading: 'Error loading canvas',
    emptyPostContentError: 'Canvas post content cannot be empty',
    inEditorError: 'Canvas app is not yet posted',
    unknownMessageError: 'Canvas: Unknown message',
  },
  chain: 'Chain',
  chainErrorRequired: 'Chain is required',
  chooseYourPicture: 'Choose your picture',
  claimed: 'Claimed',
  claim: 'Claim',
  claiming: 'Claiming',
  claimedDescription: 'You have claimed your loot',
  claimFive: 'Claim 5 Points',
  claimHundred: 'Claim 100 Points',
  claimLootbox: 'Claim lootbox',
  collab: 'Collab',
  collaborateOnAtlas3: 'Collaborate on Atlas3',
  collectionAddress: 'Collection address',
  collectionAddressErrorRequired: 'Collection address is required',
  collectionAddressErrorInvalid: 'Invalid collection address',
  collectionAddressLegend:
    'Please use the "On-chain Collection" address available on Magic Eden for any NFT from the collection',
  collectionName: 'Collection name',
  collectionNameErrorRequired: 'Collection name is required',
  collectionErrorRequired: 'Collection is required',
  collections: 'Collections',
  collectAirdrops: 'Collect free airdrops',
  comeBackIn: 'Come back in',
  comments: 'Comments',
  connectWallet: 'Connect wallet',
  continue: 'Continue',
  copied: 'Copied!',
  copiedToClipboard: 'Copied to clipboard',
  copyPrincipal: 'Copy Principal ID',
  create: 'Create',
  createAirdrop: 'Create airdrop',
  createdBy: 'Created by',
  createNewRole: 'Create new role',
  createNftCollectionError: 'There was a problem creating the NFT gating',
  createNftCollectionSuccess: 'NFT gating enabled',
  createPortal: 'Create portal',
  createPost: 'Create post',
  createRoleError: 'There was a problem creating the role',
  createRoleSuccess: 'Role created successfully',
  day: 'Day',
  days: 'days',
  delete: 'delete',
  deleteConfirmationMessage: 'Are you sure you want to delete this?',
  deleteNftCollectionError: 'There was a problem deleting the NFT gating',
  deleteNftCollectionSuccess: 'NFT gating deleted successfully',
  deleteNftGate: 'Delete NFT gate',
  description: 'Description',
  disableComments: 'Disable comments',
  dragImage: 'Click or drag here to upload your image.',
  dragMedia: 'Click or drag here to upload your images or videos.',
  dropFilesHere: 'Drop your files here',
  dscvrdPoints: 'It looks like you DSCVRed some points.',
  duration: 'Duration',
  earned: 'earned',
  edit: 'Edit',
  editProfile: 'Edit Profile',
  editProfilePhoto: 'Edit profile photo',
  editRule: 'Edit rule',
  embedImage: 'Embed Image',
  unpairedWalletNotification: "You haven't added a wallet yet",
  error: 'Error',
  errorLoadingImage: 'Error Loading...',
  explore: 'Explore',
  exploreAndDiscover: 'Explore and discover',
  exploreMore: 'Explore more',
  exploreSearchPlaceholderLarge: 'Engage with people & explore portals...',
  exploreSearchPlaceholderSmall: 'Find your community...',
  favorites: 'Favorites',
  favoritePortals: {
    addToFavorites: 'Add to Favorites',
    applyChanges: 'Apply changes',
    fave: 'Fave',
    faved: 'Faved',
    favorite: 'Favorite',
    favoritesEmptyLegend:
      'Save your favorite portals for quick access here. Expand your universe by adding a few now.',
    favoritesLegend: 'Save your favorite portals for quick access here',
    noResults: 'No results',
    otherFavorites: 'Other Favorites',
    owned: 'Owned',
    pleaseJoinToFavorite: 'Please join to favorite',
    removeFromFavorites: 'Remove from Favorites',
    reorder: 'Reorder',
    topFavorited: 'Top Favorited',
  },
  featured: 'Featured',
  featuredPortals: 'Featured portals',
  floorPrice: 'Floor price',
  follower: 'Follower',
  followers: 'Followers',
  following: 'Following',
  frame: {
    address: 'Address',
    buttonIndex: 'Button index',
    commentsMaxReached: 'Comments must be less than 1000 characters',
    debug: 'Debug',
    errorPost: 'Error performing frame post',
    flagFrame: 'Flag frame',
    frameAction: 'Frame action',
    frameIntroduction: 'Frame introduction',
    frameLink: 'Frame link',
    frameNotFoundError: 'Frame not found',
    framesValidator: 'Frames Validator',
    frameUrl: 'Frame URL',
    frameUrlErrorRequired: 'Frame URL is required',
    inputText: 'Input text',
    maxResponseTimeLegend:
      'Request took more than 5s. This may be normal: first request will take longer in development, but in production, clients will timeout requests after 5s',
    openFramesSpec: 'Open Frames Spec',
    postId: 'Post ID',
    postIdErrorInvalid: 'Invalid post id',
    request: 'Request',
    response: 'Response',
    specs: 'Specs',
    state: 'State',
    times: 'times',
    timestamp: 'Timestamp',
    transactionId: 'Transaction ID',
    trustedMessage: 'Trusted message',
  },
  gallery: 'Gallery',
  getNFT: 'Get NFT',
  gift: 'gift',
  gotToSettings: 'Go to settings',
  guest: 'Guest',
  guests: 'Guests',
  hide: 'Hide',
  home: 'home',
  hotSpots: 'Hot Spots',
  howToSetupPhantom: 'How to setup Phantom wallet',
  installMetamaskTitle: 'No metamask detected',
  installMetamaskLegend: 'Please Unlock or Install Metamask',
  invalidImageUrlError: 'Please enter a valid image url',
  invalidUrlError: 'This does not appear to be a valid url',
  invite: 'Invite',
  inviteToPortal: 'Invite to portal',
  itemId: 'Item id',
  join: 'Join',
  joined: 'joined',
  key: 'Key',
  keyboardC: 'Comment tab',
  keyboardDown: 'Scroll down',
  keyboardG: 'Gallery tab',
  keyboardL: 'Like a post',
  keyboardLeft: 'Go to previous post',
  keyboardR: 'Open reactions',
  keyboardRight: 'Go to next post',
  keyboardS: 'Toggle shortcut menu',
  keyboardShortcuts: 'Keyboard Shortcuts',
  keyboardUp: 'Scroll up',
  kick: 'Kick',
  kickMember: 'Kick member',
  lastUsed: 'Last used',
  learnMore: 'Learn More',
  leave: 'Leave',
  leaveConfirm: 'Are you sure you want to leave?',
  leavePortal: 'Leave portal',
  leavePortalLegend: 'Are you sure you want to leave this portal?',
  linkWarning: "Please only use 'https://' links",
  listedSupply: 'Listed / Supply',
  listeningNow: 'Listening Now',
  login: 'Sign in',
  lootbox: {
    creatorLootboxFound: 'Creator lootbox found!',
    criteria: 'Criteria',
    claimSubtitle: {
      sm: 'Your treasure awaits',
      md: 'Your treasure awaits. Claim the remaining loot from your opened box.',
    },
    claimTitle: {
      sm: 'Unclaimed loot!',
      md: 'You have unclaimed loot!',
    },
    claimYourLoot: {
      sm: 'Claim',
      md: 'Claim your loot',
    },
    luckyYouCreator: 'Lucky! You found a creator lootbox',
    loot: 'Loot',
    lootbox: 'Lootbox',
    lootEarned: 'Loot earned',
    mustBeMemberOf: 'Must be a member of',
    mustFollow: 'Must follow',
    pairError: 'You must have a Solana wallet paired to open this lootbox.',
  },
  luckyDay: "Hey, It's your lucky day!",
  makeCoHost: 'Make Co-host',
  managed: 'Managed',
  managePortals: 'Manage portals',
  manageWallets: 'Manage wallets',
  markAsNSFW: 'Mark as NSFW',
  marketplace: 'Marketplace',
  marketplaceCollectionsLegend:
    'Dive into a treasure trove of NFTs up for grabs. Some collections act as your golden key to unlock this amazing portal. 🔑✨',
  marketplaceCollectionItemLegend: 'Unlocks this portal. 🔑✨',
  matureContent: 'Mature content (NSFW)',
  member: 'Member',
  members: 'Members',
  memberSince: 'Member Since',
  more: 'more',
  mustJoinPortalMessage: 'You must join the portal to view this content',
  myProfile: 'My Profile',
  newRoleName: 'New role name',
  network: 'Network',
  nextPage: 'Next page',
  nftAirdrop: {
    setAsPFP: 'Set as PFP',
    viewOnMarketplace: 'View on entrepot',
  },
  nftCollection: 'Nft collection',
  nftGate: 'NFT Gate',
  nftGates: 'NFT Gates',
  nftGatesLegend:
    'NFT Gate your Portal, give your members roles based on NFT ownership.',
  nftHolderRole: 'NFT holder role',
  nfts: 'NFTs',
  nftType: 'NFT type',
  nftTypeErrorRequired: 'NFT type is required',
  noCommentsYet: 'No comments yet',
  noGalleryItems: 'No gallery items yet',
  noLanguage: "You don't have a translation language set yet!",
  noRecentPortal: 'No recent portal',
  notifications: {
    title: 'Notifications',
    portalAdmin: 'Portal admin',
    empty: {
      title: 'Stay in the loop',
      excerpt:
        'Never miss a beat when someone likes, comments or shares your posts.',
    },
    options: {
      title: 'Options',
      grouping: {
        title: 'Group notification',
        subtitle:
          'Consolidate high frequency notifications into grouped messages.',
      },
    },
    preferences: {
      title: 'Preferences',
      commentsAndReplies: 'Comments & replies',
      newFollowers: 'New followers',
      likesAndReactions: 'Likes & reactions',
      mentions: 'Mentions',
      streakReminders: 'Streak reminders via email',
    },
    content: {
      postMention: 'mentioned you in a post',
      commentMention: 'mentioned you in a comment',
      postReply: 'replied to your post',
      postMediaReply: 'replied to your media post',
      commentReply: 'replied to your comment',
      postReaction: 'reacted to your post',
      postMediaReaction: 'reacted to your media post',
      commentReaction: 'reacted to your comment',
    },
    inAppTitle: 'I want to receive',
    markAllAsRead: 'Mark all as read',
    settings: {
      streakRemindersErrorTitle: 'Please verify your email.',
    },
    viewAllNotifications: 'View all notifications',
  },
  nsfwTooltipContent: 'For mature audiences only and potentially NSFW',
  onboarding: {
    introductory: {
      welcome: 'Welcome',
      title: 'Complete onboarding for rewards',
      description:
        'We know onboarding can be tedious. To make up for it, we want to gift you the following if you complete all the steps.',
      rewards: {
        title: 'Earn rewards',
        description:
          'Finish onboarding to earn your first DSCVR Points, the social currency of DSCVR which can be spent to make your first community.',
      },
      lootbox: {
        title: 'Receive a free lootbox',
        description:
          'Lootboxes contain free NFTs, tokens, and other digital assets from the most prominent crypto-native projects and communities.',
      },
    },
    interest: {
      title: 'What are you interested in?',
      description: 'Choose 3 or more topics to help us curate your feed',
      noneOfThese: 'None of these',
    },
    discoverUsers: {
      title: 'Discover Top Users',
      description:
        'Follow our most popular users to stay updated and join the conversation.',
      follow: 'Follow',
    },
    joinPortals: {
      title: 'Join Portals',
      description: 'We found a few portals that match your interests.',
      descriptionNoInterests: 'Check out our most active and engaging portals.',
      join: 'Join',
    },
    missingSteps: {
      title: 'Complete onboarding to claim',
      user: {
        title: 'Follow at least three people',
        description: 'Connecting with other users is a core part of DSCVR.',
      },
      portal: {
        title: 'Join at least three portals',
        description:
          'Finding communities is part of what makes DSCVR fun and entertaining.',
      },
      completeOnboarding: 'Complete onboarding',
    },
    rewards: {
      title: 'You earned 50 DSCVR points just for signing up!',
      description:
        'Stack DSCVR points by claiming your daily streak, posting content that gets upvoted, and referring friends. DSCVR points can qualify you for lootboxes and access to special portals.',
      claimNow: 'Claim now',
      claimed: 'Claimed!',
    },
    nft: {
      title: 'You earned an exclusive NFT!',
      subtitle: 'Set as your profile image and earn 50 pts.',
      description:
        'Your NFT is on its way to your wallet. This can take up to an hour if network conditions are busy. If you set the NFT as your PFP, you will be rewarded with 50 DSCVR points.',
      setAsPfp: 'Set as PFP',
      viewMyWallet: 'View my wallet',
    },
    connectYourWallet: {
      title: 'Connect your wallet',
      description:
        'In order to claim this unique NFT, you must have a wallet connected.',
      connectWallet: 'Connect wallet',
      error: 'There was an error connecting your wallet',
    },
    whatIsInTheBox: 'What is in the box?!',
    unlock: 'Unlock',
    unlockLootbox: 'Unlock lootbox',
    claim: {
      tooltipContent: 'Claim your first streak to earn more points!',
    },
    newUser: 'New users receive exclusive loot!',
    chooseAtLeast3: 'Choose at least 3',
    skip: 'Skip',
  },
  open: 'Open',
  opened: 'Opened',
  opening: 'Opening',
  pair: 'Pair',
  paired: 'Paired',
  people: 'People',
  pickPortal: 'Pick Portal',
  play: 'Play',
  points: 'Points',
  pointsAdded: 'We’ve added extra 5 points to your account.',
  pointsReceived: 'Points Received',
  pollEnptyQuestionError: "Questions can't be empty",
  pollMinQuestionsError: 'You must have at least 2 poll questions',
  pollQuestionMaxCharsError: "Questions can't be more than 120 characters",
  pollRequiredVotingPeriodError: 'You must set the voting length period.',
  popularPortals: 'Popular portals',
  portal: 'Portal',
  portalDoNotExist: 'This portal does not exist',
  portalError: 'Select a portal before posting',
  portalInvite: {
    buildCommunity:
      'Build your community. Copy the referral link and download the image to invite friends to your portal.',
    buildProfile:
      'Build your community. Copy the referral link and download the image to invite friends to follow your profile.',
    inviteFriends: 'Invite your friends',
    joinPortal: 'Join this portal',
    followUser: 'Join the community',
    invited: "You've been invited!",
    invitedToJoin: "You've been invited to Join",
    invalidReferralCode: 'Invalid referral code',
    embraceFuture:
      'Embrace the future of Social Media! Engage in a decentralized community built for you!',
  },
  portalPermissionError: "You don't have permission to post to this portal.",
  portalRestrictedSubtitle: 'You don’t have access to this portal.',
  portalRestrictedNote:
    'If you just paired or acquired the NFT, it may take a few min for this Portal to unlock.',
  portalRestrictedTitle: 'Acquire an NFT to join',
  portalNSFWRestrictedError:
    'Enable NSFW in your settings to view this content',
  portals: 'Portals',
  post: 'Post',
  postButtonLabelGift: 'Gift',
  postButtonLabelReply: 'Reply',
  postButtonLabelRepost: 'Repost',
  postButtonLabelShare: 'Share',
  postButtonLabelToken: 'Tips',
  postCreated: 'Your post was successfully created',
  postDeletedConfirm: 'Your unsaved post will be deleted.',
  postError: 'Fill in the post body before posting',
  postFeature: {
    commented: 'Commented',
    errorDelete: 'There was an error deleting this post, please try again',
    errorRemove: 'There was an error removing this post, please try again',
    interacted: 'Interacted',
    originalPostDoNotExist:
      'The original post no longer exists, but you can view other popular posts.',
    postDoNotExist: 'This post does not exist',
    postRestricted: "You don't have permissions to view this post.",
    replied: 'Replied',
  },
  postUpdated: 'Your post was successfully updated',
  posts: 'Posts',
  postTo: 'Post to',
  prevPage: 'Prev page',
  primaryChain: 'Primary chain',
  primaryWallet: 'Primary',
  principalId: 'Principal ID',
  purchased: 'Purchased',
  raisedHands: 'Raised Hands',
  readMore: 'Read more',
  reason: 'Reason',
  recent: 'Recent',
  recommended: 'Recommended',
  refer: 'Refer',
  referral: 'Referral',
  referrals: 'Referrals',
  referralFeature: {
    community: {
      referrals: {
        capacitor: 'Capacitor',
        copyReferralLink: 'Copy referral link',
        description:
          'Want passive points? Refer users and receive 1 bonus point for every 10 they earn. Plus, charge up your capacitor by earning 100 bonus points to receive an Airdrop Multiplier!',
        empty: 'Empty',
        title: 'Your capacitor is on empty!',
      },
    },
    getRewards: 'Get rewards!',
    inviteYourFriends: 'Invite your friends',
    multipliers: 'Multipliers',
    multiplier: 'Multiplier',
    onboarding: {
      title: 'Connect your friends, get rewarded',
      bonus: {
        title: 'Receive Bonus Points Passively',
        description:
          'Get 1 bonus point for every 10 points your referrals earn.',
      },
      airdrop: {
        title: 'Maximize Your Airdrop Multipliers',
        description:
          "For every 100 bonus points you earn through referrals, you'll receive an Airdrop Multiplier.",
      },
    },
    tooltipContent: 'Send your friends an invite link here',
    copyLink: 'Link copied to clipboard',
  },
  remove: 'Remove',
  removeConfirmationMessage: 'Are you sure you want to remove this?',
  removeLink: 'Remove link',
  removeLinkLegend: 'Are you sure you want to remove this link?',
  removeRule: 'Remove rule',
  removeRuleLegend: 'Are you sure you want to remove this rule?',
  repliedTo: 'replied to',
  reply: 'reply',
  report: 'Report',
  requiredUrlError: 'you need a url for this',
  requiresFifty: 'Requires: 50',
  roleErrorRequired: 'Role is required',
  roleNameErrorDuplicated: 'Role name already exists',
  roleNameErrorLength: 'Role name must be between 1 and 20 characters',
  roleNameErrorRequired: 'Role name is required',
  rollTheDice: 'Roll the dice!',
  ruleTitle: 'Rule title',
  ruleDescription: 'Rule description',
  runtime: 'Runtime',
  scrollAndDiscover: 'Scroll and discover',
  search: 'Search',
  searchNftCollection: 'Search NFT collection',
  searchPortals: 'Search portals',
  selectLanguage: 'Select one in your settings.',
  selectPrimaryChain: 'Select primary chain',
  selectSiteLanguage: 'Language',
  selectSitePortalSorting: 'Portal sorting',
  selectSitePrimaryLanguage: 'Select primary language',
  selectSiteTranslatedLanguage: 'Translation',
  selectSiteTranslatedPrimaryLanguage: 'Select translation',
  send: 'Send',
  sendGift: 'Send Gift?',
  sendGiftLegend:
    'Are your sure you want to gift this NFT? This action cannot be undone.',
  setLanguage: 'Set translation language',
  userSettings: {
    address: 'Address',
    avatarRequirements: 'at least 280 x 280 px',
    coverPhotoRequirements: 'at least 1088x300 px',
    errorUploadingFile: 'There was an error uploading the file',
    invalidIcpNftAddressMessage: 'Only valid IC NFTs can be used',
    invalidImageFileMessage: 'Please upload an image file',
    updateProfileError: 'There was an error updating the profile',
    username: 'Username',
    userProfile: 'User profile',
  },
  setupNftWatcher: 'Enable NFT gating',
  setupNftWatcherSuccess: 'NFT gating is enabled',
  share: 'Share',
  sharePortal: 'Share Portal',
  shareProfile: 'Share Profile',
  show: 'Show',
  showcaseNFTs: 'Showcase your Solana NFT gallery',
  showContent: 'Show content',
  showInMarketplace: 'Show in marketplace',
  signup: 'Sign up',
  signUp: {
    thrilledRegistration:
      "We're thrilled that you've chosen to join our community of innovative thinkers and creators! Just one last step.",
    alreadyHaveAccount: 'Already have an account?',
    dontHaveAccount: 'Dont have an account yet?',
  },
  solana: {
    nft: {
      aquirred: 'NFT Acquired!',
      checkWallet: 'Check your wallet',
      visitProfile:
        'Your NFT will be displayed in your wallet. In DSCVR, visit your profile',
      display: 'Display as your avatar',
      newPicPrompt:
        "It's fresh and eye-catching. How about making it your newprofile pic?",
      clickingBuyNow: 'By clicking "Buy Now" you agree to the',
    },
  },
  sort: 'Sort',
  speaker: 'Speaker',
  speakers: 'Speakers',
  spotlight: 'Spotlight',
  status: 'Status',
  streak: {
    airdropMultiplier: 'Airdrop multiplier',
    earned: 'Earned',
    highest: 'Highest',
    highestStreak: 'Highest streak',
    streak: 'Streak',
    totalEarned: 'Total earned',
    informationDialog: {
      title: 'Multiply your chances for an exclusive Airdrop!',
      lightning: {
        title: 'Increase your chances with Streaks',
        description:
          'Log in and hit the claim button to power up your chances of receiving exclusive airdrops in your feed.',
      },
      plus: {
        title: 'Earn DSCVR points every day you log in',
        description:
          'Start collecting your points. The more streaks you hit, the more points you receive.',
      },
    },
    shareDialog: {
      title: {
        global: "Zap! You've charged up an Airdrop Multiplier! You've earned",
      },
      lightning: {
        title: 'Keep going and earn your next multiplier (they stack)',
        description:
          'Log in and hit the claim button to power up your chances of receiving exclusive airdrops in your feed.',
      },
      plus: {
        title: 'Earn DSCVR points every day you log in',
        description:
          'Start collecting your points. The more streaks you hit, the more points you receive.',
      },
      shareYourStreak: 'Share your streak',
      unlockedAn: 'unlocked an',
    },
    buttonLabel: 'Claim your points',
    duringAStreakOf: 'during a streak of',
    activeButton: {
      tooltipContent: 'Select this button to claim your points!',
    },
    activeDialog: {
      allPoweredUp: 'All powered up!',
      longStreaksEarnMore: 'long streaks earn more',
      powerUp: 'Power up your airdrop meter!',
      referFriend: 'Refer a friend',
      toClaimNextReward: 'to claim your next reward',
      unbrokenStreak: 'Unbroken streaks earn more points!',
      wantMorePoints: 'Want to earn more points?',
      youAreOnAStreak: 'You are on a streak!',
      getNotified: 'Get notified',
      optInToReceive: 'Opt in to receive email reminders',
      optedIn: 'Opted in',
      toast: {
        title: 'You will be notified!',
        description:
          'You’ve opted in to receive streak reminders via email. Visit settings to change permissions.',
        gotoSettings: 'Go to settings',
      },
    },
  },
  stay: 'Stay',
  submit: 'Submit',
  takeMeToTheUnknown: 'Take me to the unknown',
  titleError: 'Fill in the title before posting',
  tokens: 'Tokens',
  tokenTransfer: {
    amountErrorMessage: 'Amount must be greater then 0',
    balanceErrorMessage: 'Not enough balance.',
    invalidWalletErrorMessage: 'Invalid IC Wallet Principal',
    invalidWalletExtErrorMessage: 'Invalid IC Wallet Address or Principal',
    reviewTransfer: 'Review Transfer',
    title: 'NFT Transfer',
    transferNftFailedMessage: 'The NFT transfer transaction failed',
    transferTokenFailedMessage: 'The Token transfer transaction failed',
    transferNftLegend: 'Please confirm that you are about to send NFT',
    transferTokenLegend: 'Please confirm that you are about to send',
    transferWarning:
      'All transactions are irreversible, so ensure the above details are correct before you continue.',
    transferNftSuccessMessage: 'The NFT Transfer was successful',
    transferTokenSuccessMessage: 'The Token Transfer was successful',
    validationNftLegend:
      'Enter the Internet Computer wallet address you wish to transfer this NFT to.',
    validationTokenLegend:
      'Enter the Internet Computer wallet address you wish to transfer this token too.',
    walletPlaceholder: 'IC Wallet Principal',
    walletPlaceholderExt: 'IC Wallet Principal or Address',
  },
  tip: {
    contentOwnerHasNoWalletTitle: 'Content owner has no wallet',
    contentOwnerHasNoWalletDescription:
      'The content owner has not connected a wallet to receive tips',
    connectWallet: 'Connect a Solana wallet to tip',
    signatureFailed: 'Signature failed',
    preparingTipTitle: 'Preparing tip',
    preparingTipDescription: 'Please wait while we prepare your tip',
    noWalletConnectedTitle: 'No wallet connected',
    noWalletConnectedDescription: 'Please connect a wallet to tip',
    successTitle: 'Tip sent',
    successDescription: 'Your tip has been successfully sent',
  },
  top: 'Top',
  transfer: 'Transfer',
  translate: 'translate',
  unblock: 'Unblock',
  unpair: 'Unpair',
  unsavedChanges: 'Delete unsaved changes?',
  update: 'Update',
  updateNftCollectionError: 'There was a problem updating the NFT gating',
  updateNftCollectionSuccess: 'NFT gating updated successfully',
  upload: 'Upload',
  uploadImage: 'Upload image',
  uploadProfilePhoto: 'Upload profile photo',
  uploadCoverPhoto: 'Upload cover photo',
  useNFT: 'Use NFT',
  user: 'User',
  userBlocked: 'is blocked',
  userDoNotExist: 'This user does not exist',
  userNoLongerBlocked: 'is no longer blocked',
  userNoLongerFollowsYou: 'no longer follows you',
  verifiedSolanaPFPs: 'Verified Solana PFPs',
  verifyPhone: 'Verify phone',
  verifyPhoneLegend: 'You must have a verified phone to join this portal',
  view: 'View',
  viewAll: 'View all',
  viewOnMagicEden: 'View on Magic Eden',
  verifyingAtDSCVR: 'Verifying At DSCVR',
  voteCommentPermissionError: "You don't have permission to vote this comment",
  voteCommentError: 'There was an error voting this comment',
  waiting: 'Waiting',
  wallet: 'Wallet',
  wallets: {
    connectWallet: {
      connectAWallet: 'Connect a wallet',
      connecting: 'Connecting',
      errorConnectingWallet: 'Error connecting wallet',
      installed: 'Installed',
      invalidWalletConnection: 'Invalid wallet connection',
      mobileConnectError: 'Wallet cannot be connected on mobile',
      detected: 'Detected',
      notDetected: 'Not detected',
      popular: 'Popular',
    },
    evm: {
      providersNotFound: 'ethers provider not found',
    },
    sendingToWallet: {
      title: "We're sending you to your wallet",
      description:
        "Come back to DSCVR when you're finished. If you do not receive a confirmation message, reconnect your wallet.",
      takeMeToMyWallet: 'Take me to my wallet',
    },
    successfullyPaired: {
      successfullyConnected: 'Successfully connected!',
      takeMeToMyDscvrWallet: 'Take me to my DSCVR wallet',
    },
    unsuccessfulPairing: {
      inApp: {
        pairingUnsuccessful: 'Pairing unsuccessful',
        pairThisAccount: 'Pair this account instead',
        useDifferentWallet: 'Use a different wallet',
        walletAlreadyPairedTo: 'This wallet is paired to',
        pairingWillUnpairFrom: 'Pairing your wallet will unpair it from',
      },
      identity: {
        walletAlreadyPaired: 'Wallet already paired',
        walletAlreadyAssociated: 'This wallet is associated with ',
        signinWithThisAccount: 'Sign in with this account',
      },
    },
    addAnotherWallet: 'Add another wallet',
    addressCopied: 'Address copied',
    allWallets: 'All Wallets',
    failedToLoad: 'Failed to load...',
    mobileWalletPairing: 'Mobile wallet pairing',
    linkWalletFailed: 'Link Wallet Failed',
    linkWalletFailedDescription: 'Linking wallet failed for address: ',
    thanksForPairing:
      'Thank you for pairing, you can now return to your mobile browser',
    address: 'Address',
    refreshWallets: 'Refresh',
    sharingError: 'Unable to share token',
    showAllAssets: 'Show all assets',
    verifyNftPfpError: 'Error while verifying NFT',
    matrica: {
      walletsRefreshedTitle: 'Matrica wallets refreshed',
      walletsRefreshedDescription:
        'We have refreshed and retrieved your Matrica wallets.',
      refreshWalletsErrorTitle: 'Matrica Wallets Refresh Failed',
      refreshWalletsErrorDescription:
        'We were unable to refresh your Matrica wallets.',
      refreshWalletsSuccessDescription:
        "We've refreshed and retrieved your Matrica wallets.",
      linkWalletLinkErrorTitle: 'Matrica Link Failed',
      linkWalletLinkErrorDescription:
        'We were unable to link your Matrica account.',
      linkWalletUnlinkErrorTitle: 'Matrica Unlink Failed',
      linkWalletUnlinkErrorDescription:
        'We were unable to unlink your Matrica account.',
      redirectErrorMessage:
        'Oops, something went wrong linking your Matrica wallet',
      linkedText: 'Unlink',
      unlinkedText: 'Link',
    },
  },
  walletTransaction: {
    commentsErrorRequired: 'You must enter a comment',
    confirm: 'Confirm',
    confirmTransaction: 'Confirm Transaction',
    great: 'Great!',
    loadingTransactionMessage: 'Confirm connection in the extension.',
    maliciousReports: 'malicious reports',
    missingConnectedWalletError: 'No connected wallet found',
    opening: 'Opening',
    proceedAnyway: 'Proceed anyway',
    reason: 'Reason',
    reasonFraud: 'Fraud',
    reasonSpam: 'Spam',
    reasonOther: 'Other',
    reasonErrorRequired: 'Please select a reason from the list',
    redirectMessageDescription:
      'You are about to leave DSCVR, click continue to proceed.',
    redirectMessageTitle: 'Leaving DSCVR',
    report: 'Report',
    reportTransaction: 'Report transaction',
    sendingTransaction: 'Sending transaction',
    transactionGenericError: 'Error processing transaction',
    transactionNotFoundError:
      'Transaction successful but blockchain timed out. Please check Transaction ID to verify. This may take a few minutes',
    transactionId: 'Transaction ID',
    transactions: 'transactions',
    transactionSuccessMessage: 'How did this go?',
    transactionSuccessTitle: 'Transaction successful!',
    transactionReportedTitle: 'This transaction was reported',
    transactionReportedMessage:
      'This transaction has been flagged by other users as potentially malicious. DSCVR recommends cancelling this transaction.',
    transactionReportTitle: 'Report transaction',
    transactionWarning: 'Transaction Warning',
  },
  twitter: {
    oauthError:
      'Oops! Something went wrong linking your X Account. Please try again later',
    unlinkError: 'Oops! Something went wrong unlinking your X Account',
    unpairedSocialPairingText:
      'Pair your X account to add username to your profile',
    oauthSuccess: 'Successfully linked your X Account!',
    unlinkSuccess: 'Successfully unlinked your X Account',
  },
  nftMarketplace: {
    buy: 'Buy',
    buyNow: 'Buy Now',
    sold: 'Sold',
    price: 'Price',
    royalty: 'Royalty',
    takerFee: 'Taker Fee',
    tensor: { viewOnTensor: 'View on Tensor', buyOnTensor: 'Buy on Tensor' },
    magicEden: {
      viewOnMagicEden: 'View on Magic Eden',
      buyOnMagicEden: 'Buy on Magic Eden',
    },
  },
  youMightLike: 'You might like',
  verification: {
    email: {
      title: 'Get verified',
      subtitle:
        'We will send you a verification email. Click the link in the email and return to DSCVR.',
      resendTitle: 'Sent!',
      resendSubtitle:
        'Come back to DSCVR after verifying your email. To opt-in for streak notifications, click the streaks icon and select “Get notified.”',
      form: {
        errors: {
          invalid: 'Please enter a valid email',
          required: 'Email address is required',
        },
      },
      buttons: {
        sendEmail: 'Send email',
        verify: 'Verify',
        sent: 'Sent!',
        resend: 'Resend',
        verified: 'Verified',
        update: 'Update',
      },
      resendExcerpt:
        'May take a few minutes to reach your email. Check Spam just in case.',
      notifications: {
        successTitle: "You've been verified!",
        successDescription: 'Thank you for verifying your email.',
        errorTitle: 'Error when verifying email',
        errorDescription:
          'Please be sure to use the latest verification email or resend a new one.',
      },
      accountEmailUpdated: 'Email Successfully Updated',
      emailSuccessfullySent: 'Verification email sent',
      emailNotSent: 'Sending verification email failed: ',
      errors: {
        tooManyRequests: 'Too many requests',
        unknownError: 'Unknown error',
      },
    },
  },
  redirecting: 'Redirecting...',
  welcome: 'Welcome',
};
